export class LesionReportDetails {
        public id!: string;
        public number!: number;
        public angiogramName!: string;
        public angiogramId!: string;
        public userId!: string;
        public userDisplayName: string | null = null;
        public userEmailAddress: string | null = null;
        public timeElapsedMs!: number;
        public isCompleted!: boolean;
        public withDeepAngio!: boolean;
        public vesselSegment!: string;
        public lesionSeverity!: string;
        public adjunctiveTechnology!: string;
        public confidence!: string;
        public notes?: string;
        public locationX?: number;
        public locationY?: number;
        public frameSelected!: number;
        public sequenceUsed!: number; // main = 0, auxiliary = 1
        public opticalFlowUsed!: boolean;
}
