import { GetterTree } from "vuex";
import { ReportsState } from "./types";
import { RootState } from "../types";
import { LesionReportDetails } from "@/models/LesionReportDetails";

export const getters: GetterTree<ReportsState, RootState> = {
  part1ReportsCount(state): number {
    return state.allReports.filter(
      (report: LesionReportDetails) => report.withDeepAngio === false
    ).length;
  },

  part2ReportsCount(state): number {
    return state.allReports.filter(
      (report: LesionReportDetails) => report.withDeepAngio === true
    ).length;
  },

  donePart1ReportsCount(state): number {
    return state.allReports.filter(
      (report: LesionReportDetails) =>
        report.isCompleted && report.withDeepAngio === false
    ).length;
  },

  donePart2ReportsCount(state): number {
    return state.allReports.filter(
      (report: LesionReportDetails) =>
        report.isCompleted && report.withDeepAngio === true
    ).length;
  },

  selectedPart(state): number {
    return state.selectedPart;
  },

  reportsForSelectedPart(state): LesionReportDetails[] {
    return state.reportsForSelectedPart;
  },

  completedReportsForSelectedPart(state): LesionReportDetails[] {
    return state.reportsForSelectedPart.filter(
      (report: LesionReportDetails) => report.isCompleted === true
    );
  },

  uncompletedReportsForSelectedPart(state): LesionReportDetails[] {
    return state.reportsForSelectedPart.filter(
      (report: LesionReportDetails) => report.isCompleted === false
    );
  },

  selectedReport(state): LesionReportDetails {
    return state.selectedReport;
  },

  isPart1Finalized(state): boolean {
    return state.arePart1ReportsFinalized;
  },

  isPart2Finalized(state): boolean {
    return state.arePart2ReportsFinalized;
  },

  currentReportError(state): string {
    return state.currentReportError;
  }
};
