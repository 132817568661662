
import store from "@/store/index";
import { ReportsState } from "@/store/reports/types";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { State, Getter } from "vuex-class";
import { StudyPart } from "@/models/StudyProgressDetails";
import LazyInject, { Inject } from "inversify-props";
import StudyService from "@/services/impl/StudyService";
import { study } from "@/store/study";
const namespace = "reports";

@Component({
  name: "StudyHome"
})
export default class StudyHome extends Vue {
  private studyService = new StudyService();
  @State("reports") reports!: ReportsState;
  @Getter("selectedPart", { namespace }) selectedPart!: number;
  @Getter("part1ReportsCount", { namespace }) part1ReportsCount!: number;
  @Getter("part2ReportsCount", { namespace }) part2ReportsCount!: number;
  @Getter("donePart1ReportsCount", { namespace })
  donePart1ReportsCount!: number;

  @Getter("donePart2ReportsCount", { namespace })
  donePart2ReportsCount!: number;

  currentStudyPart!: StudyPart;

  async created() {
    await store.dispatch("reports/fetchReportsForUser");
    await store.dispatch("survey/fetchSurveyForUser");
    this.currentStudyPart = await this.studyService.getCurrentStudyPart();
  }

  get isStudyPartInterim() {
    return this.currentStudyPart === StudyPart.StudyPartInterim;
  }

  get isPart1Finalized() {
    return store.getters["study/isPart1Finalized"];
  }

  get isPart2Finalized() {
    return store.getters["study/isPart2Finalized"];
  }

  get isSurveyFinalized() {
    return store.getters["study/isSurveyFinalized"];
  }
}
