export class StudyProgressDetails {
  public study1Part1FinalizedDateUtc!: Date;
  public study1Part2FinalizedDateUtc!: Date;
  public study1SurveyFinalizedDateUtc!: Date;
  public study1Part2UnlockedDateUtc!: Date;
}

export enum StudyPart {
  StudyIntro = 0,
  StudyPart1 = 1,
  StudyPartInterim = 2,
  StudyPart2 = 3,
  StudySurvey = 4,
  StudyComplete = 5
}
