import { Module } from "vuex";
import { mutations } from "./mutations";
import { SnackbarState } from "./types";
import { RootState } from "../types";

export const state: SnackbarState = {
  content: "",
  color: ""
};

const namespaced = true;

// Snackbar is the module state, RootState is state for Vuex store
export const snackbar: Module<SnackbarState, RootState> = {
  namespaced,
  state,
  mutations
};
