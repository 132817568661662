import Vue, { ref } from "vue";
import { FrameDetails } from "./FrameDetails";

export enum SequenceType {
  Main = 0,
  Auxiliary = 1,
}

export class SequenceDetails {
  constructor() {
    this.frames = new Array<FrameDetails>();
  }

  public id!: string;
  public sequenceType!: SequenceType;
  public frames: Array<FrameDetails>;
  public computedFrames = ref(frames);

  public getSourceFrames(): Array<FrameDetails> {
    return this.frames.filter((f) => f.sourceFileSrc != null);
  }

  public static sequenceTypeDisplayLabel(sequence: SequenceDetails) {
    return sequence.sequenceType === SequenceType.Main ? "Main" : "Auxiliary";
  }

  public hasSourceFrames(): boolean {
    return this.getSourceFrames().length > 0;
  }

  public static getPredictionFrames(sequence: SequenceDetails): Array<FrameDetails> {
    return sequence.frames.filter((f) => f.predictionFileSrc != null);
  }

  public static hasPredictionFrames(sequence: SequenceDetails): boolean {
    return SequenceDetails.getPredictionFrames(sequence).length > 0;
  }

  public static getPredictionFramesSummary(sequence: SequenceDetails): string {
    const predictions = SequenceDetails.getPredictionFrames(sequence);
    if (predictions.length === 0) {
      return ", no prediction";
    }
    if (predictions.length === 1) {
      return ` with a prediction on frame #${predictions[0].number}`;
    }
    return `with predictions on multiple frames #${(predictions.map(p => p.number.toString()).join(", "))}`;
  }

  public static getDisplaySequenceType(sequence: SequenceDetails): string {
    // return "asdf";
    // console.info(this.sequenceType);
    // return this.sequenceType.toString();
    return sequence.sequenceType === SequenceType.Main
      ? "Main Sequence"
      : "Auxiliary Sequence";
  }
}
