
import Vue from "vue";
import { Component } from "vue-property-decorator";
import LesionReportService from "@/services/impl/LesionReportService";
import { LesionReportDetails } from "@/models/LesionReportDetails";
import VueJsonToCsv from "vue-json-to-csv";
import SurveyFormService from "@/services/impl/SurveyFormService";
import { SurveyFormDetails, SurveyFormView, SurveyQA } from "@/models/SurveyFormDetails";

export class SurveyQuestion {
  questionNumber!: number;
  text!: string;

  constructor(qIdx: number, qa: SurveyQA) {
    this.questionNumber = qIdx;
    this.text = qa.question;
  }
}

@Component({
  name: "StudyReportPage",
  components: {
    VueJsonToCsv
  }
})
export default class StudyReportPage extends Vue {
  private $self!: StudyReportPage;
  private lesionReportService = new LesionReportService();
  private surveyFormService = new SurveyFormService();
  lesionReportSearch = "";
  surveyFormSearch = "";

  lesionReportDetails = new Array<LesionReportDetails>();
  surveyFormDetails = new Array<SurveyFormView>();
  loadedSurveyQuestions = new Array<SurveyQuestion>();

  created(): void {
    this.$self = this;
  }

  public loadStudyReport(): void {
    // eslint-disable-next-line  @typescript-eslint/no-this-alias
    const $self = this;
    this.lesionReportService.getAllReports()
      .then(data => {
        $self.lesionReportDetails.splice(0, $self.lesionReportDetails.length);
        $self.lesionReportDetails.push(...data);
      });
  }

  public handleLesionReportDownloadSuccess(val: any): void {
    console.log("Study report downloaded");
  }

  public handleLesionReportDownloadError(val: any): void {
    console.log("Failed to download study report or no data");
  }

  public downloadStudyReport(): void {
    this.lesionReportService.getAllReportsAsCsv()
      .then((response: any) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${this.getLesionReportCsvFileName}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  public loadSurveyReport(): void {
    // eslint-disable-next-line  @typescript-eslint/no-this-alias
    const $self = this;
    this.surveyFormService.getAllSurveyForms()
      .then(data => {
        $self.surveyFormDetails.splice(0, $self.surveyFormDetails.length);

        if (data.length > 0) {
          const firstSurvey = data[0];
          this.loadedSurveyQuestions = firstSurvey.questions.map((q: SurveyQA, idx: number) => new SurveyQuestion(idx + 1, q));
        }

        const surveys = data.map(f => new SurveyFormView(f));
        $self.surveyFormDetails.push(...surveys);
      });
  }

  public handleSurveyReportDownloadSuccess(val: any): void {
    console.log("Survey report downloaded");
  }

  public handleSurveyReportDownloadError(val: any): void {
    console.log("Failed to download survey report or no data");
  }

  get getLesionReportCsvFileName() {
    return `DeepAngio_LesionReports_${StudyReportPage.getDateTimeStringSafeForFileNames()}`;
  }

  get getSurveyReportCsvFileName() {
    return `DeepAngio_SurveyForms_${StudyReportPage.getDateTimeStringSafeForFileNames()}`;
  }

  get surveyFormQuestions(): Array<SurveyQuestion> {
    return this.loadedSurveyQuestions;
  }

  set surveyFormQuestions(questions: Array<SurveyQuestion>) {
    this.loadedSurveyQuestions = questions;
  }

  static getDateTimeStringSafeForFileNames() {
    return new Date().toLocaleString().replace("/", ".").replace(":", ".").replace(", ", "_").replace(" ", "_");
  }

  lesionReportHeaders = [
    { text: "Lesion Report Id", filterable: true, value: "id" },
    { text: "Angiogram", filterable: true, value: "angiogramName" },
    { text: "User Id", filterable: true, value: "userId" },
    { text: "User Name", filterable: true, value: "userDisplayName" },
    { text: "User Email", filterable: true, value: "userEmailAddress" },
    { text: "Completed?", value: "isCompleted" },
    { text: "Vessel Segment", value: "vesselSegment" },
    { text: "Lesion Severity", value: "lesionSeverity" },
    { text: "Confidence", value: "confidence" },
    { text: "Adjunctive Technology", value: "adjunctiveTechnology" },
    { text: "Notes", value: "notes" }
  ];

  surveyReportHeaders = [
    { text: "Survey Id", filterable: true, value: "surveyId" },
    { text: "Completed?", filterable: true, value: "isCompleted" },
    { text: "User Name", filterable: true, value: "userDisplayName" },
    { text: "User Email", filterable: true, value: "userEmailAddress" },
    { text: "Q1", filterable: true, value: "q1Answer" },
    { text: "Q2", filterable: true, value: "q2Answer" },
    { text: "Q3", filterable: true, value: "q3Answer" },
    { text: "Q4", filterable: true, value: "q4Answer" },
    { text: "Q5", filterable: true, value: "q5Answer" },
    { text: "Q6", filterable: true, value: "q6Answer" },
    { text: "Q7", filterable: true, value: "q7Answer" },
    { text: "Q8", filterable: true, value: "q8Answer" },
    { text: "Q9", filterable: true, value: "q9Answer" },
    { text: "Q10", filterable: true, value: "q10Answer" },
    { text: "Q11", filterable: true, value: "q11Answer" },
    { text: "Q12", filterable: true, value: "q12Answer" },
    { text: "Q13", filterable: true, value: "q13Answer" },
    { text: "Q14", filterable: true, value: "q14Answer" },
    { text: "Q15", filterable: true, value: "q15Answer" }
  ];

  surveyReportCsvLabels = {
    surveyId: { title: "surveyId" },
    isCompleted: { title: "isCompleted" },
    userDisplayName: { title: "userDisplayName" },
    userEmailAddress: { title: "userEmailAddress" },
    q1Answer: { title: "q1Answer" },
    q2Answer: { title: "q2Answer" },
    q3Answer: { title: "q3Answer" },
    q4Answer: { title: "q4Answer" },
    q5Answer: { title: "q5Answer" },
    q6Answer: { title: "q6Answer" },
    q7Answer: { title: "q7Answer" },
    q8Answer: { title: "q8Answer" },
    q9Answer: { title: "q9Answer" },
    q10Answer: { title: "q10Answer" },
    q11Answer: { title: "q11Answer" },
    q12Answer: { title: "q12Answer" },
    q13Answer: { title: "q13Answer" },
    q14Answer: { title: "q14Answer" },
    q15Answer: { title: "q15Answer" }
  };

  lesionReportCsvLabels = {
    id: { title: "Report Id" },
    angiogramId: { title: "Angiogram Id" },
    userId: { title: "User Id" },
    userDisplayName: { title: "User Name" },
    userEmailAddress: { title: "User Email" },
    isCompleted: { title: "Report Completed?" },
    withDeepAngio: { title: "With DeepAngio" },
    vesselSegment: { title: "Vessel Segment" },
    lesionSeverity: { title: "Lesion Severity" },
    confidence: { title: "Confidence" },
    adjunctiveTechnology: { title: "Adjunctive Technology?" },
    notes: { title: "Notes" }
  };
}
