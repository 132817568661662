import { ActionTree } from "vuex";
import { StudyState } from "./types";
import { RootState } from "../types";
import { cid, container } from "inversify-props";
import StudyService from "@/services/impl/StudyService";
import { StudyProgressDetails } from "@/models/StudyProgressDetails";

export const actions: ActionTree<StudyState, RootState> = {
  async getStudyProgressForUser(context) {
    const studyService = new StudyService();
    await studyService
      .getStudyProgress()
      .then((progress: StudyProgressDetails) => {
        context.commit("setIsLoading", false);
        context.commit("setStudyProgress", progress);
      })
      .catch((error: Error) => console.error(error));
  },

  async resetStudyProgressForUser(context, userId: string | null) {
    const studyService = new StudyService();
    await studyService
      .resetStudyProgress(userId)
      .then((progress: StudyProgressDetails) => {
        context.commit("setIsLoading", false);
        context.commit("setStudyProgress", progress);
        context.commit("updateStudyProgress", progress);
      })
      .catch((error: Error) => console.error(error));
  },

  updateIsStudyIntroViewed(context) {
    context.commit("updateIsStudyIntroViewed");
  },

  updateStudyProgress(context, progress: StudyProgressDetails) {
    context.commit("updateStudyProgress", progress);
  },

  setError(context, error: string) {
    context.commit("setCurrentError", error);
    context.commit("addErrorToList", error);
  }
};
