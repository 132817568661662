import { GetterTree } from "vuex";
import { StudyState } from "./types";
import { RootState } from "../types";
import { StudyProgressDetails } from "@/models/StudyProgressDetails";

export const getters: GetterTree<StudyState, RootState> = {
  currentStudyProgress(state): StudyProgressDetails {
    return state.currentStudyProgress;
  },

  currentStudyProgressError(state): string {
    return state.currentStudyProgressError;
  },

  isStudyIntroViewed(state): boolean {
    return state.isStudyIntroViewed;
  },

  isPart1Finalized(state): boolean {
    return state.currentStudyProgress.study1Part1FinalizedDateUtc !== null;
  },

  isPart2Unlocked(state): boolean {
    return (
      state.currentStudyProgress.study1Part2UnlockedDateUtc !== null &&
      new Date(
        state.currentStudyProgress.study1Part2UnlockedDateUtc
      ).getTime() < new Date(Date.now()).getTime()
    );
  },

  isPart2Finalized(state): boolean {
    return state.currentStudyProgress.study1Part2FinalizedDateUtc !== null;
  },

  part2UnlockedFormattedDate(state): string {
    return new Date(
      state.currentStudyProgress.study1Part2UnlockedDateUtc
    ).toLocaleString();
  },

  isSurveyFinalized(state): boolean {
    return state.currentStudyProgress.study1SurveyFinalizedDateUtc !== null;
  }
};
