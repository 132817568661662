import { StudyPart, StudyProgressDetails } from "@/models/StudyProgressDetails";
import store from "@/store/index";
import router from "@/router/index";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
// import { IStudyService } from "../IStudyService";

// @injectable()
export default class StudyService {
  httpClient = new HttpClient();

  getStudyProgress(): Promise<StudyProgressDetails> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/Study",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.get<any, StudyProgressDetails>(getParameters);
  }

  editStudyPartProgress(partNumber: number): Promise<StudyProgressDetails> {
    const getParameters = {
      url: `/api/Study/Part/${partNumber}`,
      // url: "/bad",
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<any, StudyProgressDetails>(getParameters);
  }

  resetStudyProgress(userId: string | null): Promise<StudyProgressDetails> {
    const getParameters = {
      url: `/api/Study/resetProgress/${userId ?? "self"}`,
      // url: "/bad",
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<any, StudyProgressDetails>(getParameters);
  }

  async getCurrentStudyPart(): Promise<StudyPart> {
    const isStudyIntroViewed = store.getters["study/isStudyIntroViewed"];
    const isPart1Finalized = store.getters["study/isPart1Finalized"];
    const isPart2Unlocked = store.getters["study/isPart2Unlocked"];
    const isPart2Finalized = store.getters["study/isPart2Finalized"];
    const isSurveyFinalized = store.getters["study/isSurveyFinalized"];

    if (!isStudyIntroViewed) {
      return StudyPart.StudyIntro;
    } else if (isStudyIntroViewed && !isPart1Finalized) {
      return StudyPart.StudyPart1;
    } else if (isStudyIntroViewed && isPart1Finalized && !isPart2Unlocked) {
      return StudyPart.StudyPartInterim;
    } else if (
      isStudyIntroViewed &&
      isPart1Finalized &&
      isPart2Unlocked &&
      !isPart2Finalized
    ) {
      return StudyPart.StudyPart2;
    } else if (
      isStudyIntroViewed &&
      isPart1Finalized &&
      isPart2Finalized &&
      !isSurveyFinalized
    ) {
      return StudyPart.StudySurvey;
    } else {
      return StudyPart.StudyComplete;
    }
  }

  async redirectToCurrentStudyPart(): Promise<void> {
    const currentStudyPart = await this.getCurrentStudyPart();

    if (currentStudyPart === StudyPart.StudyIntro) {
      router.push({
        name: "StudyIntroPage"
      });
    } else if (currentStudyPart === StudyPart.StudyPart1) {
      await store.dispatch("reports/fetchReportsForUser");
      router.push({
        name: "StudyPartHomePage",
        params: { partNumber: "1" }
      });
    } else if (currentStudyPart === StudyPart.StudyPartInterim) {
      console.log("current study part is study part interim");
      router.push({
        name: "StudyPartInterimPage"
      });
    } else if (currentStudyPart === StudyPart.StudyPart2) {
      await store.dispatch("reports/fetchReportsForUser");
      router.push({
        name: "StudyPartHomePage",
        params: { partNumber: "2" }
      });
    } else if (currentStudyPart === StudyPart.StudySurvey) {
      await store.dispatch("survey/fetchSurveyForUser");
      router.push({
        name: "StudySurveyPage"
      });
    } else if (currentStudyPart === StudyPart.StudyComplete) {
      router.push({
        name: "StudyCompletePage"
      });
    } else {
      console.error(
        "current study part is null or undefined",
        store.getters["study/currentStudyPart"]
      );
    }
  }
}
