export class FrameDetails {
  public id!: string;
  public number!: number;
  public predictionFileSrc = "";
  public predictionFileSrcDownloadUrl = "";
  public predictionSeverityPercentage: number | null = null;
  public sourceFileSrc = "";
  public sourceFileSrcDownloadUrl = "";

  static isPredictionFrame(frame: FrameDetails): boolean {
    if (frame.predictionFileSrc === undefined || frame.predictionFileSrc === null || frame.predictionFileSrc === "") {
      return false;
    }
    return true;
  }

  static hasPredictionFrameDownloadUrlBeenFetched(frame: FrameDetails): boolean {
    if (!this.isPredictionFrame) {
      return false;
    }
    if (frame.predictionFileSrcDownloadUrl === undefined || frame.predictionFileSrcDownloadUrl === null || frame.predictionFileSrcDownloadUrl === "") {
      return false;
    }
    return true;
  }
}

export enum FrameFileType {
  Source = 0,
  Prediction = 1,
  Both = 2
}
