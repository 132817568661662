import "reflect-metadata";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/store/index";
import axios from "axios";
// import buildDependencyContainer from "./app.container";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import vuetify from "./plugins/vuetify";
import IdleVue from "idle-vue";

import { cid, container } from "inversify-props";
import AuthService from "./services/impl/AuthService";

import VNumeric from "vuetify-numeric/vuetify-numeric.umd";
import VueJsonToCsv from "vue-json-to-csv";
// instance static property
const authService = new AuthService();
Vue.prototype.$authService = authService;

class AppBootstrap {
  constructor() {
    this.loadDependencyContainer();
    this.loadVueApp();
  }

  private loadDependencyContainer(): void {
    // buildDependencyContainer();
  }

  private loadVueApp(): void {
    // err is error object, info is Vue specific error string, and vm is the Vue application
    Vue.config.errorHandler = function(err, vm, info) {
      console.error(`Error: ${err.toString()}\nInfo: ${info}`);
    };

    // trace is the component tree. warnHandler is ignored during production
    Vue.config.warnHandler = function(msg, vm, trace) {
      console.warn(`Warn: ${msg}\nTrace: ${trace}`);
    };

    // global error handler. source is the URL of the script
    window.onerror = function(message, source, line, column, error) {
      console.error("global error handler: ", message);
    };
    axios.interceptors.response.use(undefined, function(error) {
      if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          // const authService = container.get<IAuthService>(cid.IAuthService);
          authService.logout();
          return router.push({ name: "StudyLoginPage" });
        }
      }
    });

    Vue.config.productionTip = false;
    Vue.use(VNumeric);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);

    // // add the store as a parameter in IdleVue to access the state of idle
    // const eventsHub = new Vue();
    // Vue.use(IdleVue, {
    //   eventEmitter: eventsHub,
    //   store,
    //   idleTime: 1000 * 60 * 25, // 1800000 = 30 minutes
    //   startAtIdle: false
    // });

    const vm = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount("#app");
  }
}

// eslint-disable-next-line no-new
new AppBootstrap();
