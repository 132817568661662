
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Inject } from "inversify-props";
import UploadFilesService from "../../services/impl/UploadFilesService";
import { SequenceDetails, SequenceType } from "@/models/SequenceDetails";
import { FrameFileType } from "@/models/FrameDetails";

@Component({
  name: "FileUploader"
})
export default class StudyFileUploader extends Vue {
  private uploadFilesService = new UploadFilesService();

  selectedFiles: FileList | null = null;
  progress = 0;
  message = "";
  fileInfos: File[] | null = null;
  clearFrames = false;
  isLoading = false;

  @Prop() angiogramId!: string;
  @Prop() sequenceType!: SequenceType;
  @Prop() sequence!: SequenceDetails;
  @Prop() frameFileType!: FrameFileType;
  // @Prop() uploadSuccessCallback: Function | null = null;
  // @Prop() uploadFailureCallback: Function | null = null;

  // computed property
  get sequenceTypeDisplay() {
    return this.sequenceType === SequenceType.Main ? "Main" : "Auxiliary";
  }

  get frameTypeDisplay() {
    switch (this.frameFileType) {
      case FrameFileType.Source:
        return "Source";
      case FrameFileType.Prediction:
        return "Prediction";
      case FrameFileType.Both:
        return "Source + Prediction";
    }
    // return this.frameFileType === FrameFileType.Source ? "Source" : "Prediction";
  }

  mounted() {
    // this.uploadFilesService.getFiles().then(response => {
    //   this.fileInfos = response.data;
    // });
  }

  // get the selected files from v-file-input element in html template
  selectFiles(fileList: FileList) {
    this.progress = 0;
    this.selectedFiles = fileList;
    console.log("selectedFiles length: ", this.selectedFiles);
    console.log(
      "current sequence frames length: ",
      this.sequence.frames.length
    );
  }

  async upload() {
    this.message = "";

    /* if they haven't selected any files, prompt them to do so
    else if replacing a sequence with one containing fewer frames, we need to wipe old sequence frames */
    if (!this.selectedFiles) {
      this.message = "Please select a file!";
      return true;
    } else if (this.selectedFiles.length < this.sequence.frames.length) {
      // this.clearFrames = confirm(
      //   "You are replacing a sequence with one with fewer frames. Do you want to clear all frames from previous sequence?"
      // );
      // /* if they don't want to wipe old sequence frames, don't proceed with upload */
      // if (this.clearFrames === false) return true;
    }
    // We are not clearing any frame anymore
    this.clearFrames = false;

    this.isLoading = true;

    this.uploadFilesService
      .upload(
        this.selectedFiles,
        (event: any) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
          console.log("progress: ", this.progress);
        },
        this.angiogramId,
        this.sequenceType,
        this.frameFileType,
        this.clearFrames
      )
      .then(() => {
        this.isLoading = false;
        this.$store.commit("snackbar/showSnackbar", {
          content: "Angiogram uploaded successfully.",
          color: "success"
        });
        this.$emit("update-angiogram", this.angiogramId);
        /*         this.uploadFilesService.getFiles().then(response => {
          this.fileInfos = response.data;
        }); */
      })
      .catch((e: Error) => {
        console.error(e);
        this.progress = 0;
        // this.message = "Could not upload the file!";
        this.$store.commit("snackbar/showSnackbar", {
          content: "Could not upload the file!",
          color: "error"
        });
        this.selectedFiles = null;
      });
  }
}
