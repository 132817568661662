import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ReportsState } from "./types";
import { RootState } from "../types";
import { LesionReportDetails } from "@/models/LesionReportDetails";

export default class StudyPartMetadata {
  title!: string;
  description!: string;
}

export const state: ReportsState = {
  allReports: [],
  selectedPart: 0,
  reportsForSelectedPart: [],
  selectedReport: new LesionReportDetails(),
  isLoading: true,
  arePart1ReportsFinalized: false,
  arePart2ReportsFinalized: false,
  studyPartMetadata: [
    { title: "Part 1", description: "DICOM Viewer" },
    { title: "Part 2", description: "DeepAngio" }
  ],
  currentReportError: null,
  reportErrors: []
};

const namespaced = true;

// ReportsState is the module state, RootState is state for Vuex store
export const reports: Module<ReportsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
