import { Module } from "vuex";
import { mutations } from "./mutations";
import { StudyState } from "./types";
import { RootState } from "../types";
import { StudyProgressDetails } from "@/models/StudyProgressDetails";
import { getters } from "./getters";
import { actions } from "./actions";

export const state: StudyState = {
  currentStudyProgress: new StudyProgressDetails(),
  isStudyIntroViewed: false,
  isLoading: true,
  currentStudyProgressError: null,
  studyProgressErrors: []
};

const namespaced = true;

// Survey is the module state, RootState is state for Vuex store
export const study: Module<StudyState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
