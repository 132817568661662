
import Vue from "vue";
import { Component } from "vue-property-decorator";
import MarketingForm from "./MarketingForm.vue";

@Component({
  components: {
    MarketingForm
  }
})
export default class MarketingFooter extends Vue {}
