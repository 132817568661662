
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ContactForm } from "@/models/ContactForm";
import { Inject } from "inversify-props";
import ContactFormService from "@/services/impl/ContactFormService";

@Component
export default class MarketingForm extends Vue {
  private contactFormService = new ContactFormService();

  $refs!: {
    form: any;
  };

  contactForm = new ContactForm();
  formValidity = false;
  fromName = "";
  fromEmail = "";
  message = "";
  formSubmittedSuccessfully = false;

  nameRules = [(value: string) => !!value || "Name is required."];

  emailRules = [
    (value: string) => !!value || "Email is required.",
    (value: string) =>
      /.+@.+\..+/.test(value) || "Please provide a valid email address."
  ];

  messageRules = [(value: string) => !!value || "Message is required"];

  async submitContactForm() {
    await this.contactFormService
      .submitContactForm(this.contactForm)
      .then(() => {
        this.$store.commit("snackbar/showSnackbar", {
          content: "Thank you for your response. We will do our best to get back to you as soon as we can.",
          color: "success"
        });
        this.$refs.form.reset();
      })
      .catch(() => this.$store.commit("snackbar/showSnackbar", {
        content: "Something went wrong. Please try again later."
      }));
  }
}
