import { GetterTree } from "vuex";
import { SurveyState } from "./types";
import { RootState } from "../types";
import { SurveyFormDetails } from "@/models/SurveyFormDetails";

export const getters: GetterTree<SurveyState, RootState> = {
  currentSurvey(state): SurveyFormDetails {
    return state.currentSurvey;
  },

  currentSurveyError(state): string {
    return state.currentSurveyError;
  }
};
