
import Vue from "vue";
import { LesionReportDetails } from "@/models/LesionReportDetails";
import { Component } from "vue-property-decorator";
import { State, Getter, Action } from "vuex-class";
import { ReportsState } from "../../store/reports/types";
import StudyService from "@/services/impl/StudyService";
import { Inject } from "inversify-props";
import store from "@/store/index";
import { StudyProgressDetails } from "@/models/StudyProgressDetails";
import AppDialog from "../AppDialog.vue";
import ErrorHandlingService from "@/services/impl/ErrorHandlingService";
const namespace = "reports";

@Component({
  name: "StudyPartReportCollection",
  components: { AppDialog }
})
export default class StudyPartReportCollection extends Vue {
  private studyService = new StudyService();
  private errorHandlingService = new ErrorHandlingService();

  @State("reports") reports!: ReportsState;
  @Action("setSelectedReport", { namespace }) setSelectedReport!: any;
  @Action("finalizeSelectedPartReports", { namespace })
  finalizeSelectedPartReports!: any;

  @Getter("reportsForSelectedPart", { namespace })
  reportsForSelectedPart!: LesionReportDetails[];

  @Getter("completedReportsForSelectedPart", { namespace })
  completedReportsForSelectedPart!: LesionReportDetails[];

  @Getter("uncompletedReportsForSelectedPart", { namespace })
  uncompletedReportsForSelectedPart!: LesionReportDetails[];

  @Getter("selectedPart", { namespace }) selectedPart!: number;

  loadingFinalizeReports = false;

  $refs!: {
    reportConfirm: any;
  };

  get userId() {
    const currentUser = store.getters["auth/currentUser"];
    return currentUser.id;
  }

  get isUserAdmin() {
    const currentUser = store.getters["auth/currentUser"];
    return currentUser.isAdmin;
  }

  get areAllSelectedPartReportsCompleted() {
    if (
      this.completedReportsForSelectedPart.length > 0 &&
      this.completedReportsForSelectedPart.length ===
        this.reportsForSelectedPart.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isCurrentStudyPartFinalized() {
    if (this.selectedPart === 1) {
      return store.getters["study/isPart1Finalized"];
    } else if (this.selectedPart === 2) {
      return store.getters["study/isPart2Finalized"];
    } else {
      console.error("selected part does not equal 1 or 2");
      return false;
    }
  }

  redirectToFormPage(report: LesionReportDetails) {
    this.setSelectedReport(report);
    this.$router.push({
      name: "StudyPartFormPage",
      params: { partNumber: this.selectedPart.toString(), reportId: report.id }
    });
  }

  async confirmFinalize() {
    if (
      await this.$refs.reportConfirm.open(
        `Finalize Part ${this.selectedPart} Reports`,
        `Are you sure you want to finalize your Part ${this.selectedPart} reports? If you choose to continue, you will no longer be able to edit your Part ${this.selectedPart} lesion reports.`
      )
    ) {
      await this.finalizeReports();
    }
  }

  async finalizeReports() {
    this.loadingFinalizeReports = true;

    // then make http post request
    await this.studyService
      .editStudyPartProgress(this.selectedPart)
      .then((studyProgress: StudyProgressDetails) => {
        this.loadingFinalizeReports = false;

        this.$store.commit("snackbar/showSnackbar", {
          content: `You have successfully finalized your Part ${this.selectedPart} lesion reports.`,
          color: "success"
        });

        // ajax call was successful, so update the study progress in Vuex store
        store.dispatch("study/updateStudyProgress", studyProgress);

        // then redirect to appropriate page
        this.studyService.redirectToCurrentStudyPart();
      })
      .catch(async error => {
        this.loadingFinalizeReports = false;
        await this.errorHandlingService.handleError(error, "study");
      });
  }
}
