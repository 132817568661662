
import Vue from "vue";
import { Component } from "vue-property-decorator";
import StudyHome from "../components/study/StudyHome.vue";

@Component({
  name: "StudyHomePage",
  components: {
    StudyHome
  }
})
export default class StudyHomePage extends Vue {}
