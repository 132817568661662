
// import { IAuthService } from "@/services/IAuthService";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Inject } from "inversify-props";
import { User } from "oidc-client";
import { Action, Getter } from "vuex-class";
import store from "@/store/index";
import StudyService from "@/services/impl/StudyService";
import AuthService from "@/services/impl/AuthService";
const namespace = "reports";

@Component({
  name: "Callback"
})
export default class Callback extends Vue {
  // @Inject() private authService!: IAuthService;
  @Action("fetchReportsForUser", { namespace }) fetchReportsForUser!: any;
  @Action("setSelectedPartAndReports", { namespace })

  private authService!: AuthService;

  private studyService = new StudyService();
  setSelectedPartAndReports!: any;
  isError = false;
  errorMessage = "";

  async created() {
    // when this component is created, isLoading should be false
    this.authService = (this as any).$authService as AuthService;
    if (!this.authService) {
      throw new Error("Cannot access (this as any).$authService as AuthService");
    }
    try {
      const user = await this.authService.signinRedirectCallback();
      if (user === null) {
        this.isError = true;
      } else {
        const authenticatedUser = user as User;
        // await (this as any).$authService.postSigninRedirectCallback(authenticatedUser);
        await store.dispatch("auth/loginUser", user);
        await store.dispatch("auth/fetchAllUsers");
        await store.dispatch("study/getStudyProgressForUser").then(() => {
          this.studyService.redirectToCurrentStudyPart();
        });
        await store.dispatch("survey/fetchSurveyForUser");
      }
    } catch (e) {
      const error = e as Error;
      console.error(error);
      console.error(error.message);
      this.errorMessage = error.message;
      this.$router.push({ name: "Unauthorized" });
    }
  }
}
