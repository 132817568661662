
import Vue from "vue";
import { Component } from "vue-property-decorator";
import StudySurvey from "@/components/study/StudySurvey.vue";
@Component({
  components: {
    StudySurvey
  }
})
export default class StudySurveyPage extends Vue {}
