
import Vue, { watch, ref } from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Inject } from "inversify-props";
import AngiogramService from "@/services/impl/AngiogramService";
import { AngiogramDetails } from "@/models/AngiogramDetails";
import { SequenceDetails, SequenceType } from "@/models/SequenceDetails";
import StudyFileUploader from "../components/study/StudyFileUploader.vue";
import { FrameFileType } from "@/models/FrameDetails";
import Constants from "@/services/Constants";

@Component({
  name: "AngiogramEditorPage",
  components: {
    StudyFileUploader
  }
})
export default class AngiogramEditorPage extends Vue {
  private angiogramService = new AngiogramService();
  allAngiograms = [] as AngiogramDetails[];
  angiogramToCreate = new AngiogramDetails();
  selectedAngiogram: AngiogramDetails | null = null;
  show = false;
  sequenceDetailsClass = SequenceDetails;

  mainSequenceType = SequenceType.Main;
  auxSequenceType = SequenceType.Auxiliary;
  frameFileTypeSource = FrameFileType.Source;
  frameFileTypePrediction = FrameFileType.Prediction;

  angioCreatorIsLoading = false;
  angioUpdaterIsLoading = false;
  renderComponent = true;

  vesselSegments = Constants.lesionLocations;

  search = "";

  headers = [
    {
      text: "Frame Number",
      filterable: true,
      value: "number"
    },
    {
      text: "Source File",
      filterable: true,
      value: "sourceFileSrc"
    },
    {
      text: "Prediction File",
      filterable: true,
      value: "predictionFileSrc"
    },
    {
      text: "Prediction % severity",
      value: "predictionSeverityPercentage"
    },
    {
      text: "DB Id",
      value: "id"
    }
  ];

  get mainSequence(): SequenceDetails | null {
    if (this.selectedAngiogram === null) {
      return null;
    }
    const mainSeqs = this.selectedAngiogram.sequences.filter(
      s => s.sequenceType === SequenceType.Main
    );
    if (mainSeqs.length > 0) {
      return mainSeqs[0];
    }
    const sequence = new SequenceDetails();
    sequence.sequenceType = SequenceType.Main;
    return sequence;
  }

  get hasMainSequence(): boolean {
    return this.mainSequence != null;
  }

  get hasAuxSequence(): boolean {
    return this.auxSequence != null;
  }

  get auxSequence(): SequenceDetails | null {
    if (this.selectedAngiogram === null) {
      return null;
    }
    const auxSeqs = this.selectedAngiogram.sequences.filter(
      s => s.sequenceType === SequenceType.Auxiliary
    );
    if (auxSeqs.length > 0) {
      return auxSeqs[0];
    }
    const sequence = new SequenceDetails();
    sequence.sequenceType = SequenceType.Auxiliary;
    return sequence;
  }

  get orderedSequences(): SequenceDetails[] {
    if (this.selectedAngiogram === null) {
      return [];
    }
    return this.selectedAngiogram.sequences.sort((a, b) =>
      a.sequenceType > b.sequenceType ? 1 : -1
    );
  }

  async forceRender() {
    // Remove MyComponent from the DOM
    this.renderComponent = false;
    const old = this.selectedAngiogram;
    this.selectedAngiogram = null;
    this.selectedAngiogram = old;
    // Then, wait for the change to get flushed to the DOM
    await this.$nextTick();

    // Add MyComponent back in
    this.renderComponent = true;
  }

  /* after file uploader uploads frames to angiogram, we need to update the
  angiogram to display the correct frames in the expansion panels */
  updateAngiogram(angiogramId: string) {
    console.log("need to fix this");
    this.angioUpdaterIsLoading = true;
    this.angiogramService.getRefreshAngiogram(angiogramId, true)
      .then(updated => {
        this.updateLocalAngiogram(updated);
        this.angioUpdaterIsLoading = false;
        return this.forceRender();
      });
  }

  mounted() {
    this.angiogramService
      .getAllAngiograms()
      .then((angiograms: AngiogramDetails[]) => {
        this.allAngiograms = angiograms;
        // by default just set the selected angiogram to the first one when angiograms are loaded
        this.selectedAngiogram = this.allAngiograms[0];
      });
    // this.$watch("allAngiograms", function(newVal: AngiogramDetails[], oldVal: AngiogramDetails[]) {
    //   console.log("asdf", newVal, oldVal);
    // }, {
    //   deep: true
    // });
  }

  createAngiogram() {
    this.angiogramService.createAngiogram(this.angiogramToCreate).then((created) =>
      this.angiogramService
        .getAllAngiograms()
        .then((angiograms: AngiogramDetails[]) => {
          this.allAngiograms = angiograms;
          const matches = this.allAngiograms.filter(a => a.id === created.id);
          if (matches.length === 1) {
            this.selectedAngiogram = matches[0];
            this.$store.commit("snackbar/showSnackbar", {
              content: "Angiogram created. You can edit it below.",
              color: "success"
            });
            this.show = false;
            this.angiogramToCreate = new AngiogramDetails();
          } else {
            this.$store.commit("snackbar/showSnackbar",
              {
                content: "Failed to find created angiogram.",
                color: "error"
              });
          }
        })
        .catch(err => {
          console.error("failed to create the angiogram", err);
          this.$store.commit("snackbar/showSnackbar",
            {
              content: "Failed to create the angiogram.",
              color: "error"
            });
        })
    );
  }

  editAngiogram() {
    if (this.selectedAngiogram === null) {
      return null;
    }
    this.angioUpdaterIsLoading = true;
    this.angiogramService.updateAngiogram(this.selectedAngiogram)
      .then(updated => {
        this.updateAngiogram(updated.id);
        this.$store.commit("snackbar/showSnackbar", {
          content: "Angiogram edited successfully.",
          color: "success"
        });
      });
  }

  deleteAngioData(sequence: SequenceDetails, frameFileType: FrameFileType) {
    if (this.selectedAngiogram === null) {
      return;
    }
    this.angioUpdaterIsLoading = true;
    return this.angiogramService.deleteAngiogramData(this.selectedAngiogram, sequence.sequenceType, frameFileType)
      .then(() => {
        this.updateAngiogram(this.selectedAngiogram!.id);
        this.$store.commit("snackbar/showSnackbar", {
          content: "Angiogram edited successfully.",
          color: "success"
        });
        this.angioUpdaterIsLoading = false;
      })
      .catch(err => {
        console.error("failed to delete data for angiogram", err);
        this.$store.commit("snackbar/showSnackbar",
          {
            content: "Failed to delete data for angiogram.",
            color: "error"
          });
        this.angioUpdaterIsLoading = false;
      });
  }

  private updateLocalAngiogram(fromServerAngio: AngiogramDetails): void {
    // var matches = this.allAngiograms.filter((a, i) => a.id === fromServerAngio.id);
    // if (matches.length)
    let index = -1;
    for (let i = 0, len = this.allAngiograms.length; i < len; i++) {
      if (this.allAngiograms[i].id === fromServerAngio.id) {
        index = i;
        break;
      }
    }
    if (index === -1) {
      return;
    }
    this.$set(this.allAngiograms, index, fromServerAngio);
  }

  setSelectedAngiogram(angiogram: AngiogramDetails) {
    this.selectedAngiogram = angiogram;
  }
}
