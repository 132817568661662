import { LesionReportDetails } from "@/models/LesionReportDetails";
import { MutationTree } from "vuex";
import { state } from ".";
import { getters } from "./getters";
import { ReportsState } from "./types";

export const mutations: MutationTree<ReportsState> = {
  setIsLoading(state, status: boolean) {
    state.isLoading = status;
  },

  setAllReports(state, reports: LesionReportDetails[]) {
    state.allReports = reports;
  },

  setSelectedPart(state, partNumber: number) {
    state.selectedPart = partNumber;
  },

  setReportsForSelectedPart(state, partNumber) {
    // filter by withDeepAngio and sort lesion reports numerically
    if (partNumber === 1) {
      state.reportsForSelectedPart = state.allReports
        .filter((report: LesionReportDetails) => report.withDeepAngio === false)
        .sort((a, b) => (a.number > b.number ? 1 : -1));
    } else if (partNumber === 2) {
      state.reportsForSelectedPart = state.allReports
        .filter((report: LesionReportDetails) => report.withDeepAngio === true)
        .sort((a, b) => (a.number > b.number ? 1 : -1));
    } else {
      console.error("partNumber does not equal 1 or 2");
    }
  },

  setSelectedReport(state, report: LesionReportDetails) {
    state.selectedReport = report;
  },

  updateSelectedReport(state, form) {
    state.selectedReport.vesselSegment = form.vesselSegment;
    state.selectedReport.lesionSeverity = form.lesionSeverity;
    state.selectedReport.adjunctiveTechnology = form.adjunctiveTechnology;
    state.selectedReport.confidence = form.confidence;
    state.selectedReport.notes = form.notes;
    state.selectedReport.isCompleted = form.isCompleted;
    state.selectedReport.timeElapsedMs = form.timeElapsedMs;
  },

  finalizeSelectedPartReports(state, partNumber) {
    if (partNumber === 1) {
      state.arePart1ReportsFinalized = true;
    } else if (partNumber === 2) {
      state.arePart2ReportsFinalized = true;
    } else {
      console.error("part number does not equal 1 or 2");
    }
  },

  setCurrentError(state, error) {
    state.currentReportError = error;
  },

  addErrorToList(state, error) {
    state.reportErrors.push(error);
  }
};
