
import Vue from "vue";
import { Component } from "vue-property-decorator";
import store from "@/store/index";
import StudyService from "@/services/impl/StudyService";
import { Inject } from "inversify-props";

@Component({
  name: "StudyIntroPage"
})
export default class StudyIntroPage extends Vue {
  private studyService = new StudyService();

  mounted() {
    store.dispatch("study/updateIsStudyIntroViewed");
  }

  continueToStudy() {
    this.studyService.redirectToCurrentStudyPart();
  }
}
