
import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppBar from "../components/AppBar.vue";

@Component({
  name: "StudyLayout",
  components: {
    AppBar
  }
})
export default class StudyLayout extends Vue {
  // links = [
  //   {
  //     label: "Part 1",
  //     component: { name: "StudyPartHomePage", params: { partNumber: "1" } }
  //   },
  //   {
  //     label: "Part 2",
  //     component: { name: "StudyPartHomePage", params: { partNumber: "2" } }
  //   },
  //   {
  //     label: "Survey",
  //     component: { name: "StudySurveyPage" }
  //   }
  // ];

  // adminLinks = [
  //   {
  //     label: "Users",
  //     component: { name: "UserPage" }
  //   },
  //   {
  //     label: "Angiograms",
  //     component: { name: "AngiogramEditorPage" }
  //   }
  // ];
}
