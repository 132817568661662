import { AngiogramDetails } from "@/models/AngiogramDetails";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
import LocalStorageService from "./LocalStorageService";
import ConfigProvider from "@/ConfigProvider";
import { FrameFileType } from "@/models/FrameDetails";
import { SequenceType } from "@/models/SequenceDetails";

// @injectable()
export default class AngiogramService {
  private httpClient = new HttpClient();
  private localStorageService = new LocalStorageService();

  getAngiogram(id: string): Promise<AngiogramDetails> {
    return this.getRefreshAngiogram(id, false);
  }

  getRefreshAngiogram(
    id: string,
    refresh: boolean
  ): Promise<AngiogramDetails> {
    return new Promise<AngiogramDetails>((resolve, reject) => {
      this.localStorageService
        .get<AngiogramDetails>(id, "angiogram")
        .then((response: AngiogramDetails) => {
          // if response is not null, this means angiogram already exists so we can just return it
          if (response !== null && refresh === false) {
            resolve(response);
          } else {
            // response is null, so we must fetch it and then call AddToLocalStorage()
            const getParameters = {
              url: "/api/angiogram/" + encodeURIComponent(id),
              requiresToken: true,
              headers: null
            };
            this.httpClient
              .get<any, AngiogramDetails>(getParameters)
              .then((response: AngiogramDetails) => {
                if (response !== null) {
                  const angio = response;
                  this.localStorageService.set<AngiogramDetails>(
                    id,
                    "angiogram",
                    angio
                  );
                  resolve(angio);
                }
              });
          }
        });
    });
  }

  getAllAngiograms(): Promise<AngiogramDetails[]> {
    const getParameters = {
      url: "/api/angiogram/",
      requiresToken: true,
      headers: null
    };
    return this.httpClient.get<any, AngiogramDetails[]>(getParameters);
  }

  createAngiogram(angiogram: AngiogramDetails): Promise<AngiogramDetails> {
    const getParameters = {
      url: "/api/angiogram/create",
      payload: angiogram,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<AngiogramDetails, AngiogramDetails>(
      getParameters
    );
  }

  updateAngiogram(angiogram: AngiogramDetails): Promise<AngiogramDetails> {
    const getParameters = {
      url: "/api/angiogram/update",
      payload: angiogram,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<AngiogramDetails, AngiogramDetails>(
      getParameters
    );
  }

  deleteAngiogramData(
    angiogram: AngiogramDetails,
    sequenceType: SequenceType,
    frameFileType: FrameFileType
  ): Promise<AngiogramDetails> {
    const postParameters = {
      url:
        "/api/angiogram/delete/" +
        angiogram.id +
        "/" +
        sequenceType +
        "/" +
        frameFileType,
      requiresToken: true,
      headers: null
    };

    return this.httpClient.post<AngiogramDetails, AngiogramDetails>(
      postParameters
    );
  }
}
