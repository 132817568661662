var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('app-dialog',{ref:"reportConfirm"}),_c('div',{attrs:{"id":"heading-and-finalize"}},[_c('h2',{staticStyle:{"font-weight":"bold"},attrs:{"id":"page-heading"}},[_vm._v(" Part "+_vm._s(_vm.reports.selectedPart)+": Lesion Interpretation using "+_vm._s(_vm.reports.studyPartMetadata[_vm.reports.selectedPart - 1].description)+" ")]),(_vm.areAllSelectedPartReportsCompleted)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingFinalizeReports,"disabled":_vm.isCurrentStudyPartFinalized},on:{"click":function($event){return _vm.confirmFinalize()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v("Finalize Part "+_vm._s(_vm.selectedPart)+" Reports")],1):_vm._e()],1),_c('v-row',{attrs:{"id":"list-items"}},[(!_vm.areAllSelectedPartReportsCompleted)?_c('v-col',{class:{
        'one-col': _vm.completedReportsForSelectedPart.length === 0,
        'two-col': _vm.completedReportsForSelectedPart.length !== 0
      }},[_c('h3',{staticClass:"status-heading"},[_vm._v(" Remaining: "+_vm._s(_vm.uncompletedReportsForSelectedPart.length)+" ")]),_vm._l((_vm.uncompletedReportsForSelectedPart),function(report){return _c('v-card',{key:report.id,staticClass:"list-item",attrs:{"outlined":""}},[_c('div',{staticClass:"lesion-and-status"},[_c('h4',{attrs:{"id":"lesion-with-number"}},[_vm._v("Case "+_vm._s(report.number))]),_c('p',[_vm._v("Vessel Segment: "+_vm._s(report.vesselSegment)),(_vm.isUserAdmin)?_c('span',[_c('br'),_vm._v(" Report Id: "+_vm._s(report.id)),_c('br'),_vm._v(" Angiogram Id: "+_vm._s(report.angiogramId)),_c('br'),_vm._v(" Angiogram Name: "+_vm._s(report.angiogramName)+" ")]):_vm._e()])]),_c('v-btn',{staticClass:"view-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.redirectToFormPage(report)}}},[_vm._v(" View ")])],1)})],2):_vm._e(),(
        _vm.completedReportsForSelectedPart.length !== 0 &&
          !_vm.areAllSelectedPartReportsCompleted
      )?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(_vm.completedReportsForSelectedPart.length !== 0)?_c('v-col',{class:{
        'one-col': _vm.areAllSelectedPartReportsCompleted,
        'two-col': !_vm.areAllSelectedPartReportsCompleted
      }},[_c('h3',{staticClass:"status-heading"},[_vm._v(" Completed: "+_vm._s(_vm.completedReportsForSelectedPart.length)+" ")]),_vm._l((_vm.completedReportsForSelectedPart),function(report){return _c('v-card',{key:report.id,staticClass:"list-item",attrs:{"outlined":""}},[_c('div',{staticClass:"lesion-and-status"},[_c('h4',{attrs:{"id":"lesion-with-number"}},[_vm._v("Case "+_vm._s(report.number))]),_c('p',[_vm._v("Vessel Segment: "+_vm._s(report.vesselSegment)),(_vm.isUserAdmin)?_c('span',[_c('br'),_vm._v(" Report Id: "+_vm._s(report.id)),_c('br'),_vm._v(" Angiogram Id: "+_vm._s(report.angiogramId)),_c('br'),_vm._v(" Angiogram Name: "+_vm._s(report.angiogramName)+" ")]):_vm._e()])]),_c('v-btn',{staticClass:"edit-button",attrs:{"dark":"","disabled":_vm.isCurrentStudyPartFinalized,"color":"#6c757d"},on:{"click":function($event){return _vm.redirectToFormPage(report)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit ")],1)],1)})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }