
import Vue from "vue";
import store from "@/store/index";
import { Component } from "vue-property-decorator";
import { Inject } from "inversify-props";
import StudyService from "@/services/impl/StudyService";

@Component({
  name: "StudyPartInterimPage"
})
export default class StudyPartInterimPage extends Vue {
  private studyService = new StudyService();

  get part2UnlockedDate() {
    return store.getters["study/part2UnlockedFormattedDate"];
  }
}
