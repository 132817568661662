// import dotenv from "dotenv";
// dotenv.config({ path: "../deepangioweb.container.env", debug: true });

export default class ConfigProvider {
  public static get CONFIG() {
    // const host = import.meta.env.VUE_APP_DEEPANGIOWEB_HOST;
    return {
      host: "$DEEPANGIOWEB_HOST",
      backendBaseUrl: "$DEEPANGIOWEB_BACKEND_BASE_URL",
      ssoAuthority: "$DEEPANGIOWEB_SSO_AUTHORITY"
    };
  }

  public static value(name: keyof typeof ConfigProvider.CONFIG) {
    if (!(name in this.CONFIG)) {
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      return;
    }

    if (value.startsWith("$DEEPANGIOWEB_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName] || process.env["VUE_APP_" + envName];
      if (envValue) {
        return envValue;
      } else {

      }
    } else {
      return value;
    }
  }
}
