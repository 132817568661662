
import store from "@/store/index";
import { SurveyFormDetails, SurveyQA, SurveyQuestionType } from "@/models/SurveyFormDetails";
import SurveyFormService from "@/services/impl/SurveyFormService";
import { Inject } from "inversify-props";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AppDialog from "../AppDialog.vue";
import StudyService from "@/services/impl/StudyService";
import { StudyProgressDetails } from "@/models/StudyProgressDetails";
import ErrorHandlingService from "@/services/impl/ErrorHandlingService";
const namespace = "survey";

@Component({
  components: { AppDialog }
})
export default class StudySurvey extends Vue {
  private surveyFormService = new SurveyFormService();
  private errorHandlingService = new ErrorHandlingService();
  studyService = new StudyService();
  @Getter("currentSurvey", { namespace }) currentSurvey!: SurveyFormDetails;

  $refs!: {
    surveyConfirm: any;
  };

  // TODO: missing strongly
  radioLabels = [
    "Strongly agree",
    "Mildly agree",
    "Neutral",
    "Mildly Disagree",
    "Strongly disagree"
  ];

  radioRules = [(value: string) => !!value];

  // // hardcoding these for ease of use
  // numRadioQuestions = 10;
  // numTextAreaQuestions = 5;

  isLoading = false;

  formValidity = false;

  surveyForm = new SurveyFormDetails();

  // get totalNumQuestions() {
  //   return this.numRadioQuestions + this.numTextAreaQuestions;
  // }

  get allQuestions() {
    return this.currentSurvey.questions;
    // const questions = new Array<SurveyQA>();
    // for (let i = 0; i < this.currentSurvey.questions.length; i++) {
    //   const qa = this.currentSurvey.questions[i];
    //   questions.push(qa);
    // }
    // return questions;
  }

  get radioQuestions() {
    return this.allQuestions.filter(qa => qa.questionType === SurveyQuestionType.Radio);
  }

  get textAreaQuestions() {
    return this.allQuestions.filter(qa => qa.questionType === SurveyQuestionType.Text);
  }

  // set the empty survey answers equal to the answers stored in Vuex
  async created() {
    await store.dispatch("survey/fetchSurveyForUser");
    setTimeout(() => {
      this.surveyForm.id = this.currentSurvey.id;
      this.surveyForm.isCompleted = this.currentSurvey.isCompleted;
      this.surveyForm.questions = this.currentSurvey.questions;
      this.surveyForm.studyNumber = this.currentSurvey.studyNumber;
    }, 2000);
  }

  async confirmSubmit() {
    if (
      await this.$refs.surveyConfirm.open(
        "Finalize Survey",
        "Are you sure you want to submit your survey? Once you submit, you will be unable to edit your responses."
      )
    ) {
      await this.onSubmit();
    }
  }

  async onSubmit() {
    this.isLoading = true;

    if (!this.surveyForm.isCompleted) {
      this.surveyForm.isCompleted = true;
    }

    // then make http post request
    await this.surveyFormService
      .submitSurvey(this.surveyForm)
      .then((progress: StudyProgressDetails) => {
        this.isLoading = false;
        // post request was successful, so set the current survey instance and finalize survey
        // maybe we need a method in study service (submit survey) which submits and finalizes
        store.dispatch("survey/updateCurrentSurvey", this.surveyForm);
        store.dispatch("study/updateStudyProgress", progress);

        // show success snackbar
        this.$store.commit("snackbar/showSnackbar", {
          content: "Your survey has been submitted successfully.",
          color: "success"
        });

        // then redirect to appropriate page
        this.studyService.redirectToCurrentStudyPart();
      })
      .catch(async error => {
        this.isLoading = false;
        await this.errorHandlingService.handleError(error, "survey");
      });

    this.isLoading = false;
  }
}
