export default class LocalStorageService {
  // typeof()
  get<T>(id: string, objectType: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      // first check if object already exists in local storage
      const storageObject = localStorage.getItem(objectType + "_" + id);

      // if it does, just convert string into object and resolve
      if (storageObject !== null) {
        const dataObject = JSON.parse(storageObject);
        resolve(dataObject as T);
      } else {
        resolve(null as unknown as T);
      }
    });
  }

  set<T>(id: string, objectType: string, storageObject: T): void {
    const dataObject = JSON.stringify(storageObject);
    localStorage.setItem(objectType + "_" + id, dataObject);
  }

  clear(): void {
    localStorage.clear();
  }
};
