import { SurveyFormDetails } from "@/models/SurveyFormDetails";
import { MutationTree } from "vuex";
import { state } from ".";
import { getters } from "./getters";
import { SurveyState } from "./types";

export const mutations: MutationTree<SurveyState> = {
  setIsLoading(state, status: boolean) {
    state.isLoading = status;
  },

  setCurrentSurvey(state, survey: SurveyFormDetails) {
    state.currentSurvey = survey;
  },

  updateCurrentSurvey(state, survey: SurveyFormDetails) {
    state.currentSurvey.questions = survey.questions;
    state.currentSurvey.isCompleted = survey.isCompleted;
  },

  setCurrentError(state, error) {
    state.currentSurveyError = error;
  },

  addErrorToList(state, error) {
    state.surveyErrors.push(error);
  }
};
