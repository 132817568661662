import { GetterTree } from "vuex";
import { AuthState } from "./types";
import { RootState } from "../types";
import { User } from "oidc-client";
import { UserDetails } from "@/models/UserDetails";

const matchEmailFromPrincipal = function(
  principal: User,
  userEmail: string
): boolean {
  // Email will either be an 'object' or a 'string';
  // if it's an object, it's an array of string.
  let extractedEmail: string | null = null;
  if (typeof principal.profile.email === "string") {
    extractedEmail = principal.profile.email;
  }
  if (typeof principal.profile.email === "object") {
    if ((principal.profile.email as unknown as Array<string>).length > 0) {
      extractedEmail = (principal.profile.email as unknown as Array<string>)[0];
    }
  }

  if (extractedEmail === null) {
    const jsonUser = JSON.stringify(principal);
    throw new Error(
      `"No email could be extracted from principal.\n${jsonUser}"`
    );
  }

  return extractedEmail.toUpperCase() === userEmail.toUpperCase();
};

export const getters: GetterTree<AuthState, RootState> = {
  allUsers(state): UserDetails[] {
    return state.allUsers;
  },

  principal(state): User {
    return state.principal;
  },

  token(state): string {
    return state.token;
  },

  currentUser(state): UserDetails {
    return state.allUsers.filter((user: UserDetails) =>
      matchEmailFromPrincipal(state.principal, user.email)
    )[0];
  },

  isLoggedIn(state): boolean {
    if (state.token === "") {
      return false;
    } else {
      return true;
    }
  }
};
