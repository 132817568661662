import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import StudyHomePage from "../views/StudyHomePage.vue";
import StudyPartHomePage from "../views/StudyPartHomePage.vue";
import StudyLoginPage from "../views/StudyLoginPage.vue";
import StudySurveyPage from "../views/StudySurveyPage.vue";
import StudyPartFormPage from "../views/StudyPartFormPage.vue";
import UserPage from "../views/UserPage.vue";
import Callback from "../views/Callback.vue";
import PostLogout from "../views/PostLogout.vue";
import SilentRenew from "../views/SilentRenew.vue";
import Unauthorized from "../views/Unauthorized.vue";
import StudyLayout from "../layouts/StudyLayout.vue";
import StudyViewerLayout from "../layouts/StudyViewerLayout.vue";
import ViewerTestPage from "../views/ViewerTestPage.vue";
import store from "@/store/index";
import AngiogramEditorPage from "../views/AngiogramEditorPage.vue";
import MarketingPage from "../views/MarketingPage.vue";
import StudyCompletePage from "../views/StudyCompletePage.vue";
import StudyPartInterimPage from "../views/StudyPartInterimPage.vue";
import StudyIntroPage from "../views/StudyIntroPage.vue";
import StudyReportPage from "@/views/StudyReportPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "MarketingPage",
    component: MarketingPage
  },
  {
    path: "/study",
    name: "StudyHomePage",
    component: StudyHomePage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutPage.vue")
  },
  {
    path: "/study/login",
    name: "StudyLoginPage",
    component: StudyLoginPage
  },
  {
    path: "/study/part/:partNumber",
    name: "StudyPartHomePage",
    component: StudyPartHomePage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/viewertest",
    name: "ViewerTestPage",
    component: ViewerTestPage,
    meta: {
      layout: StudyViewerLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/part/:partNumber/report/:reportId",
    name: "StudyPartFormPage",
    component: StudyPartFormPage,
    meta: {
      layout: StudyViewerLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/survey",
    name: "StudySurveyPage",
    component: StudySurveyPage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/user",
    name: "UserPage",
    component: UserPage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/callback",
    name: "Callback",
    component: Callback
  },
  {
    path: "/study/unauthorized",
    name: "Unauthorized",
    component: Unauthorized
  },
  {
    path: "/study/intro",
    name: "StudyIntroPage",
    component: StudyIntroPage,
    meta: { layout: StudyLayout }
  },
  {
    path: "/study/silent-renew",
    name: "SilentRenew",
    component: SilentRenew
  },
  {
    path: "/study/post-logout",
    name: "PostLogout",
    component: PostLogout
  },
  {
    path: "/study/angiogram-editor",
    name: "AngiogramEditorPage",
    component: AngiogramEditorPage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/reporting",
    name: "StudyReportPage",
    component: StudyReportPage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  },
  {
    path: "/study/complete",
    name: "StudyCompletePage",
    component: StudyCompletePage,
    meta: {
      layout: StudyLayout
    }
  },
  {
    path: "/study/interim",
    name: "StudyPartInterimPage",
    component: StudyPartInterimPage,
    meta: {
      layout: StudyLayout,
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters["auth/isLoggedIn"];
    if (isAuthenticated) {
      next();
      return true;
    }
    next("/study/login");
  } else {
    next();
  }
});

export default router;
