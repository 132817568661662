import { FileDownload } from "@/models/FileDownload";
import { Inject, injectable } from "inversify-props";
// import { IFileDownloadService } from "../IFileDownloadService";
import HttpClient from "./HttpClient";
import ConfigProvider from "@/ConfigProvider";

// @injectable()
export default class FileDownloadService {
  private httpClient = new HttpClient();

  getFileDownload(
    angioId: string,
    sequenceType: string,
    frameFileType: string,
    relativeFilePath: string
  ): Promise<FileDownload> {
    // prepare our request parameters
    const getParameters = {
      url:
        "/file/" +
        encodeURIComponent(angioId) +
        "/" +
        sequenceType +
        "/" +
        frameFileType +
        "/" +
        encodeURIComponent(relativeFilePath),
      requiresToken: true,
      headers: null
    };
    return this.httpClient
      .get<any, FileDownload>(getParameters)
      .then((fileDownload: FileDownload) => {
        const getParams = {
          url: fileDownload.downloadUrl,
          requiresToken: true,
          headers: null
        };
        return this.httpClient
          .getRawResponse(getParams)
          .then((response: any) => {
            fileDownload.downloadUrl = response.data;
            return fileDownload;
          });
      });
  }
}
