export class SurveyFormDetails {
  public id!: string;
  public userId!: string;
  public userDisplayName!: string;
  public userEmailAddress!: string;
  public studyNumber!: number;
  public questions!: Array<SurveyQA>;
  public isCompleted!: boolean;
}

export class SurveyQA {
  public question!: string;
  public answer!: string;
  public questionType!: SurveyQuestionType;
}

export enum SurveyQuestionType {
  Radio = 0,
  Text = 1,
}

export class SurveyFormView {
  public surveyId!: string;
  public userId!: string;
  public userDisplayName!: string;
  public userEmailAddress!: string;
  public isCompleted!: boolean;
  public q1Answer!: string;
  public q2Answer!: string;
  public q3Answer!: string;
  public q4Answer!: string;
  public q5Answer!: string;
  public q6Answer!: string;
  public q7Answer!: string;
  public q8Answer!: string;
  public q9Answer!: string;
  public q10Answer!: string;
  public q11Answer!: string;
  public q12Answer!: string;
  public q13Answer!: string;
  public q14Answer!: string;
  public q15Answer!: string;

  constructor(survey: SurveyFormDetails) {
    const data = survey;
    this.surveyId = data.id;
    this.userId = data.userId;
    this.userDisplayName = data.userDisplayName?.replace(/[\r\n]+/g, " ");
    this.userEmailAddress = data.userEmailAddress?.replace(/[\r\n]+/g, " ");
    this.isCompleted = data.isCompleted;
    this.q1Answer = data.questions[0]?.answer?.replace(/[\r\n]+/g, " ");
    this.q2Answer = data.questions[1]?.answer?.replace(/[\r\n]+/g, " ");
    this.q3Answer = data.questions[2]?.answer?.replace(/[\r\n]+/g, " ");
    this.q4Answer = data.questions[3]?.answer?.replace(/[\r\n]+/g, " ");
    this.q5Answer = data.questions[4]?.answer?.replace(/[\r\n]+/g, " ");
    this.q6Answer = data.questions[5]?.answer?.replace(/[\r\n]+/g, " ");
    this.q7Answer = data.questions[6]?.answer?.replace(/[\r\n]+/g, " ");
    this.q8Answer = data.questions[7]?.answer?.replace(/[\r\n]+/g, " ");
    this.q9Answer = data.questions[8]?.answer?.replace(/[\r\n]+/g, " ");
    this.q10Answer = data.questions[9]?.answer?.replace(/[\r\n]+/g, " ");
    this.q11Answer = data.questions[10]?.answer?.replace(/[\r\n]+/g, " ");
    this.q12Answer = data.questions[11]?.answer?.replace(/[\r\n]+/g, " ");
    this.q13Answer = data.questions[12]?.answer?.replace(/[\r\n]+/g, " ");
    this.q14Answer = data.questions[13]?.answer?.replace(/[\r\n]+/g, " ");
    this.q15Answer = data.questions[14]?.answer?.replace(/[\r\n]+/g, " ");
  }
}
