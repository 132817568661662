import { LesionReportDetails } from "@/models/LesionReportDetails";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
// import { ILesionReportService } from "../ILesionReportService";
import ConfigProvider from "@/ConfigProvider";

// @injectable()
export default class LesionReportService {
  httpClient = new HttpClient();

  getReportsForUser(): Promise<LesionReportDetails[]> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/LesionReport",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.get<any, LesionReportDetails[]>(getParameters);
  }

  getAllReportsAsCsv(): Promise<any> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/LesionReport/all?output=csv",
      requiresToken: true,
      headers: null,
      responseType: "blob" // important
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.postRaw<any>(getParameters);
  }

  getAllReports(): Promise<LesionReportDetails[]> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/LesionReport/all?output=json",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.post<any, LesionReportDetails[]>(getParameters);
  }

  submitLesionReportForm(
    form: LesionReportDetails
  ): Promise<LesionReportDetails> {
    const getParameters = {
      url: "/api/LesionReport",
      // url: "/bad",
      payload: form,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<LesionReportDetails, LesionReportDetails>(
      getParameters
    );
  }
}
