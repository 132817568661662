import { StudyProgressDetails } from "@/models/StudyProgressDetails";
import { MutationTree } from "vuex";
import { state } from ".";
import { getters } from "./getters";
import { StudyState } from "./types";

export const mutations: MutationTree<StudyState> = {
  setIsLoading(state, status: boolean) {
    state.isLoading = status;
  },

  setStudyProgress(state, progress: StudyProgressDetails) {
    state.currentStudyProgress = progress;
  },

  updateIsStudyIntroViewed(state) {
    if (state.isStudyIntroViewed === false) {
      state.isStudyIntroViewed = true;
    }
  },

  updateStudyProgress(state, progress: StudyProgressDetails) {
    state.currentStudyProgress.study1Part1FinalizedDateUtc =
      progress.study1Part1FinalizedDateUtc;
    state.currentStudyProgress.study1Part2FinalizedDateUtc =
      progress.study1Part2FinalizedDateUtc;
    state.currentStudyProgress.study1SurveyFinalizedDateUtc =
      progress.study1SurveyFinalizedDateUtc;
    state.currentStudyProgress.study1Part2UnlockedDateUtc =
      progress.study1Part2UnlockedDateUtc;
  },

  setCurrentError(state, error) {
    state.currentStudyProgressError = error;
  },

  addErrorToList(state, error) {
    state.studyProgressErrors.push(error);
  }
};
