import { render, staticRenderFns } from "./Unauthorized.vue?vue&type=template&id=65701d59&scoped=true&"
var script = {}
import style0 from "./Unauthorized.vue?vue&type=style&index=0&id=65701d59&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.7.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65701d59",
  null
  
)

export default component.exports