import ConfigProvider from "@/ConfigProvider";
import { ContactForm } from "@/models/ContactForm";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";

// @injectable()
export default class ContactFormService {
  private httpClient = new HttpClient();

  submitContactForm(form: ContactForm): Promise<void> {
    const getParameters = {
      url: "/api/ContactForm",
      payload: form,
      requiresToken: false,
      headers: null
    };
    return this.httpClient.post<ContactForm, void>(getParameters);
  }
}
