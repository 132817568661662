
import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppBar from "../components/AppBar.vue";

@Component({
  name: "StudyViewerLayout",
  components: {
    AppBar
  }
})
export default class StudyViewerLayout extends Vue {

}
