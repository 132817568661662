/* eslint-disable @typescript-eslint/camelcase */
import { UserManager, WebStorageStateStore, User } from "oidc-client";
// import { Inject, injectable } from "inversify-props";
// import store from "@/store/index";
import HttpClient from "./HttpClient";
// import { IAuthService } from "../IAuthService";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfigProvider from "@/ConfigProvider";

const baseRedirectUri = ConfigProvider.value("host");
const STS_DOMAIN = ConfigProvider.value("ssoAuthority");

// @injectable()
@Component
export default class AuthService extends Vue {
  // implements IAuthService
  // @Inject() private httpClient!: IHttpClient;

  public userManager: UserManager;
  public principal!: User | null;
  public authToken = "";
  public loading = true;
  public isAuthenticated = false;
  public popupOpen = false;
  public error!: any;

  private settings: any = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: STS_DOMAIN,
    client_id: "deepangio",
    redirect_uri: `${baseRedirectUri}/study/callback`,
    post_logout_redirect_uri: `${baseRedirectUri}/study/post-logout`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${baseRedirectUri}/study/silent-renew`,
    response_type: "code",
    scope: "openid profile api3 offline_access email",
    checkSessionInterval: 1000,
    accessTokenExpiringNotificationTime: 15
  };

  constructor() {
    super();
    this.userManager = new UserManager(this.settings);
  }

  created() {
    this.userManager.events.addUserSignedIn(this.onUserSignedIn);
    this.userManager.events.addUserLoaded(this.onUserLoaded);
    this.userManager.events.addAccessTokenExpiring(
      this.onAccessTokenExpiring
    );
    this.userManager.events.addSilentRenewError(
      this.onSilentRenewError
    );
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public async login(): Promise<void> {
    try {
      await this.userManager.signinRedirect();
    } catch (e) {
      // eslint-disable-next-line no-debugger
      debugger;
      this.error = e;
    }
    return Promise.resolve();
  }

  public async signinPopupCallback(): Promise<User | null> {
    this.loading = true;
    try {
      const callbackState = await this.userManager.signinPopupCallback();
      this.principal = await this.userManager.getUser();
      this.authToken = this.principal?.access_token || "";
    } catch (e) {
      this.error = e;
    } finally {
      this.loading = false;
    }
    return Promise.resolve(this.principal);
  }

  public async signinRedirectCallback(): Promise<User | null> {
    this.loading = true;
    try {
      const callbackState =
        await this.userManager.signinRedirectCallback();
      this.principal = await this.userManager.getUser();
      this.authToken = this.principal?.access_token || "";
      this.isAuthenticated = true;
    } catch (e) {
      this.error = e;
      return Promise.reject(e);
    } finally {
      this.loading = false;
    }
    return Promise.resolve(this.principal);
  }

  public async signoutPopupCallback(): Promise<void> {
    try {
      const callbackState =
        await this.userManager.signoutPopupCallback();
    } catch (e) {
      this.error = e;
    }
    return Promise.resolve();
  }

  public async signinSilentCallback(): Promise<void> {
    try {
      const callbackState =
        await this.userManager.signinSilentCallback();
    } catch (e) {
      this.error = e;
    }
    return Promise.resolve();
  }

  public async logout(): Promise<void> {
    return await this.userManager.signoutRedirect();
  }

  public async getAccessToken(): Promise<string> {
    const dataAccessToken = await this.userManager
      .getUser()
      .then((data: any) => {
        return data.access_token;
      });
    return dataAccessToken;
  }

  onUserSignedIn(): void {
    console.log("on user signed in");
    // i think this is the first time a user signs in. we want to redirect them to study instructions
  }

  onUserLoaded(): void {
    console.log("user session has been established or re-established");
  }

  onAccessTokenExpiring(): void {
    console.log("access token expiring...");
  }

  onSilentRenewError(): void {
    console.error("silent renew error");
  }
}
