import { ActionTree } from "vuex";
import { ReportsState } from "./types";
import { RootState } from "../types";
import { LesionReportDetails } from "@/models/LesionReportDetails";
import { cid, container } from "inversify-props";
import LesionReportService from "@/services/impl/LesionReportService";

export const actions: ActionTree<ReportsState, RootState> = {
  async fetchReportsForUser(context) {
    const lesionReportService = new LesionReportService();
    await lesionReportService
      .getReportsForUser()
      .then((reports: LesionReportDetails[]) => {
        context.commit("setIsLoading", false);
        context.commit("setAllReports", reports);
      })
      .catch((error: Error) => console.error(error));
  },

  setSelectedPartAndReports(context, partNumber: number) {
    context.commit("setSelectedPart", partNumber);
    context.commit("setReportsForSelectedPart", partNumber);
  },

  setSelectedReport(context, report) {
    context.commit("setSelectedReport", report);
  },

  updateSelectedReport(context, form: LesionReportDetails) {
    context.commit("updateSelectedReport", form);
  },

  finalizeSelectedPartReports(context, partNumber: number) {
    context.commit("finalizeSelectedPartReports", partNumber);
  },

  setError(context, error: string) {
    context.commit("setCurrentError", error);
    context.commit("addErrorToList", error);
  }
};
