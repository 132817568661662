
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class AppDialog extends Vue {
  dialog = false;
  message = "";
  title = "";

  resolve!: any;
  reject!: any;

  options = {
    color: "grey lighten-3",
    width: 400,
    zIndex: 200,
    noconfirm: false
  };

  open(title: string, message: string, options: any) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree() {
    this.resolve(true);
    this.dialog = false;
  }

  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
