import { UserDetails } from "@/models/UserDetails";
import { MutationTree } from "vuex";
import { AuthState } from "./types";
import { User } from "oidc-client";

export const mutations: MutationTree<AuthState> = {
  setAllUsers(state, users: UserDetails[]) {
    state.allUsers = users;
  },

  setPrincipal(state, principal: User) {
    state.principal = principal;
  },

  setToken(state, token: string) {
    state.token = token;
  },

  removePrincipal(state) {
    state.principal = null;
  },

  removeToken(state) {
    state.token = "";
  }
};
