import { LogDetails } from "@/models/LogDetails";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
import store from "@/store/index";
import ConfigProvider from "@/ConfigProvider";

// @injectable()
export default class LogService {
  private httpClient = new HttpClient();

  // accept error object, create logDetails dto, then submit to server
  logError(error: any): Promise<void> {
    const logDetails = new LogDetails();
    const currentUser = store.getters["auth/currentUser"];

    // then fill properties of logDetails with those in the error parameter
    logDetails.userId = currentUser.id;
    logDetails.message = error.config
      ? `error message: ${error.message} in url ${error.config.url}`
      : `error message: ${error.message}`;
    logDetails.LogLevel = 4; // LogEventLevel for error = 4
    logDetails.clientException = error.toString(); // JSON.stringify(error) returns {}

    const getParameters = {
      url: "/api/logging/log",
      payload: logDetails,
      requiresToken: false,
      headers: null
    };
    return this.httpClient.post<LogDetails, void>(getParameters);
  }
}
