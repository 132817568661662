import { StudyProgressDetails } from "@/models/StudyProgressDetails";
import { SurveyFormDetails } from "@/models/SurveyFormDetails";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
// import { ISurveyFormService } from "../ISurveyFormService";

// @injectable()
export default class SurveyFormService {
  httpClient = new HttpClient();

  fetchSurveyForUser(): Promise<SurveyFormDetails> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/SurveyForm",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.get<any, SurveyFormDetails>(getParameters);
  }

  getAllSurveyForms(): Promise<SurveyFormDetails[]> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/SurveyForm/all?output=json",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.post<any, SurveyFormDetails[]>(getParameters);
  }

  submitSurvey(survey: SurveyFormDetails): Promise<StudyProgressDetails> {
    const getParameters = {
      url: "/api/SurveyForm",
      payload: survey,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<SurveyFormDetails, StudyProgressDetails>(
      getParameters
    );
  }
}
