import { Inject, injectable } from "inversify-props";
import store from "@/store/index";
import LogService from "./LogService";

// @injectable()
export default class ErrorHandlingService {
  private logService = new LogService();

  async handleError(error: any, vuexModule: string): Promise<void> {
    if (error.response) {
      // client received an error response(5xx, 4xx)
      console.error("Client received error response ", error);
    } else if (error.request) {
      // client never received response or request never left
      console.error(
        "Client never received response or request never left",
        error
      );
    } else if (error) {
      console.error("ErrorHandlingService", error);
    }
    store.commit("snackbar/showSnackbar", {
      content:
        "Something went wrong. Please check your internet connection and try again. If the problem persists, please give us a call",
      color: "error"
    });
    // add error to Vuex
    store.dispatch(`${vuexModule}/setError`, error);

    // we also need to send the error to the server to be handled by the LogController
    await this.logService.logError(error);
  }
}
