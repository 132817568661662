
import Vue from "vue";
import { UserDetails } from "@/models/UserDetails";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Inject } from "inversify-props";
import UserService from "@/services/impl/UserService";
// import { IAuthService } from "@/services/IAuthService";
const namespace = "auth";

@Component
export default class UserPage extends Vue {
  private userService = new UserService();
  //  @Inject() private authService!: IAuthService;

  @Action("fetchAllUsers", { namespace }) fetchAllUsers!: any;
  @Getter("allUsers", { namespace }) allUsers!: UserDetails[];
  @Getter("token", { namespace }) token!: string;
  @Getter("isLoggedIn", { namespace }) isLoggedIn!: boolean;
  @Getter("principal", { namespace }) principal!: any;
  @Getter("currentUser", { namespace }) currentUser!: UserDetails;

  user = new UserDetails();
  formValidity = false;
  isEditing = false;

  groupIdOptions = [1, 2];

  addNewUser() {
    this.isEditing = false;
    this.user = new UserDetails();
  }

  async onSubmit() {
    // create new user
    if (this.isEditing === false) {
      // instead call Vuex action registerUser()
      await this.userService
        .createUser(this.user)
        .then(() => {
          this.fetchAllUsers().then(() => {
            this.user = new UserDetails();
            this.$store.commit("snackbar/showSnackbar", {
              content: "User created successfully.",
              color: "success"
            });
          });
        })
        .catch(() =>
          this.$store.commit("snackbar/showSnackbar", {
            content: "Failed to create new user.",
            color: "error"
          })
        );
    } else {
      // update user
      this.userService
        .updateUser(this.user)
        .then(() => {
          this.$store.commit("snackbar/showSnackbar", {
            content: "User updated successfully.",
            color: "success"
          });
          this.fetchAllUsers().then(() => {
            this.user = new UserDetails();
          });
        })
        .catch(() =>
          this.$store.commit("snackbar/showSnackbar", {
            content: "Failed to update user.",
            color: "error"
          })
        );
    }
    this.isEditing = false;
  }

  deleteUser(user: UserDetails) {
    this.userService
      .deleteUser(user)
      .then(() => {
        this.$store.commit("snackbar/showSnackbar", {
          content: "User deleted successfully.",
          color: "success"
        });
        this.fetchAllUsers();
      })
      .catch(() =>
        this.$store.commit("snackbar/showSnackbar", {
          content: "Failed to delete user.",
          color: "error"
        })
      );
  }

  async selectUser(user: UserDetails) {
    this.user = user;
    this.isEditing = true;
  }

  async resetUserStudyProgress(user: UserDetails) {
    await this.selectUser(user);
    await this.$store.dispatch("study/resetStudyProgressForUser", this.user.id).then(() => {
      // this.studyService.redirectToCurrentStudyPart();
      this.$store.commit("snackbar/showSnackbar", {
        content: "User progress reset successfully.",
        color: "success"
      });
    });
  }

  getUserClaims() {
    this.userService
      .getClaims()
      .then((response: any) => {
        console.log(response);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
}
