
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ReportsState } from "../../store/reports/types";
import { State, Getter, Action } from "vuex-class";
import { LesionReportDetails } from "@/models/LesionReportDetails";
import { Inject } from "inversify-props";
import LesionReportService from "@/services/impl/LesionReportService";
import StudyService from "@/services/impl/StudyService";
import ErrorHandlingService from "@/services/impl/ErrorHandlingService";
import Constants from "@/services/Constants";
import { UserDetails } from "@/models/UserDetails";
import { AngiogramDetails } from "@/models/AngiogramDetails";
const namespace = "reports";

@Component({
  name: "StudyReportForm"
})
export default class StudyReportForm extends Vue {
  private lesionReportService = new LesionReportService();
  private errorHandlingService = new ErrorHandlingService();
  private studyService = new StudyService();

  @State("reports") reports!: ReportsState;
  @Getter("selectedReport", { namespace }) selectedReport!: LesionReportDetails;
  @Getter("selectedPart", { namespace }) selectedPart!: number;
  @Getter("currentReportError", { namespace }) currentReportError!: any;
  @Getter("currentUser", { namespace: "auth" }) currentUser!: UserDetails;
  @Action("updateSelectedReport", { namespace }) updateSelectedReport!: any;
  @Action("setError", { namespace }) setError!: any;

  isLoading = false;

  segmentOptions = Constants.lesionLocations;

  $refs!: {
    lesionSeverityNumericInput: any;
    // cornerstoneDeepangioTarget: any;
  };

  severityOptions = [
    "0% (No lesion)",
    "1-49%",
    "50% - 69%",
    "70% - 89%",
    "90% - 100%"
  ];

  confidenceOptions = ["Low", "Medium", "High"];

  technologyOptions = ["No", "Yes, FFR", "Yes, IVUS"];

  segmentRules = [
    (value: string) => !!value || "Coronary vessel segment is required."
  ];

  severityRules = [
    (value: string) => !!value || "Lesion severity is required."
  ];

  confidenceRules = [(value: string) => !!value || "Confidence is required."];

  technologyRules = [
    (value: string) => !!value || "Adjunctive technology is required."
  ];

  form = new LesionReportDetails();
  formValidity = false;

  startTime = 0;
  endTime = 0;
  timeElapsedMs = 0;

  created() {
    // set the empty form to the selectedReport properties stored in Vuex
    this.form.id = this.selectedReport.id;
    this.form.isCompleted = this.selectedReport.isCompleted;
    this.form.vesselSegment = this.selectedReport.vesselSegment;
    this.form.lesionSeverity = this.selectedReport.lesionSeverity;
    this.form.adjunctiveTechnology = this.selectedReport.adjunctiveTechnology;
    this.form.confidence = this.selectedReport.confidence;
    this.form.notes = this.selectedReport.notes;
    this.form.timeElapsedMs = this.selectedReport.timeElapsedMs;
  }

  mounted() {
    // this.$refs.lesionSeverityNumericInput.min = 0;
    // this.$refs.lesionSeverityNumericInput.max = 100;
    // get the time when component is first mounted
    // Date.now() returns the number of milliseconds between now and Jan 1, 1970
    this.startTime = Date.now();
  }

  async onSubmit() {
    // form submit is loading
    this.isLoading = true;
    // get the time when user submits form
    this.endTime = Date.now();
    // get the timeElapsed (in milliseconds) to complete form
    this.form.timeElapsedMs = this.endTime - this.startTime;

    // then complete the report
    if (!this.form.isCompleted) {
      this.form.isCompleted = true;
    }

    // then make http post request
    await this.lesionReportService
      .submitLesionReportForm(this.form)
      .then((reportForm: LesionReportDetails) => {
        this.isLoading = false;
        this.$store.commit("snackbar/showSnackbar", {
          content: "Your response has been submitted successfully.",
          color: "success"
        });
        // ajax call was successful, so update the selectedReport properties in Vuex store
        this.updateSelectedReport(reportForm);

        // then redirect to study part home page
        this.studyService.redirectToCurrentStudyPart();
      })
      .catch(async error => {
        this.isLoading = false;
        await this.errorHandlingService.handleError(error, "reports");
      });
  }

  cancelKeyEvents(event: { key: string; stopPropagation: () => void }) {
    if (
      event.key === " " ||
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft"
    ) {
      event.stopPropagation();
    }
  }
}
