import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AuthState } from "./types";
import { RootState } from "../types";

export const state: AuthState = {
  allUsers: [],
  principal: null,
  token: ""
};

const namespaced = true;

// AuthState is the module state, RootState is state for Vuex store
export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
