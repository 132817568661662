import { UserDetails } from "@/models/UserDetails";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
// import { IUserService } from "../IUserService";
import ConfigProvider from "@/ConfigProvider";

// @injectable()
export default class UserService {
  private httpClient = new HttpClient();

  getAllUsers(): Promise<UserDetails[]> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/user",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.get<any, UserDetails[]>(getParameters);
  }

  createUser(user: UserDetails): Promise<UserDetails> {
    const getParameters = {
      url: "/api/user/create",
      payload: user,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<UserDetails, UserDetails>(getParameters);
  }

  updateUser(user: UserDetails): Promise<UserDetails> {
    const getParameters = {
      url: "/api/user/update",
      payload: user,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<UserDetails, UserDetails>(getParameters);
  }

  deleteUser(user: UserDetails): Promise<UserDetails> {
    const getParameters = {
      url: "/api/user/disable",
      payload: user,
      requiresToken: true,
      headers: null
    };
    return this.httpClient.post<UserDetails, UserDetails>(getParameters);
  }

  getClaims(): Promise<string> {
    // prepare our request parameters
    const getParameters = {
      url: "/api/identity",
      requiresToken: true,
      headers: null
    };
    // just return httpClient.get (which is a promise) or again use async/await if you prefer
    return this.httpClient.get<any, string>(getParameters);
  }
}
