import axios, { AxiosRequestConfig } from "axios";
import { IHttpClientRequestParameters } from "../IHttpClientRequestParameters";
import axiosRetry from "axios-retry";
import store from "@/store/index";
import { injectable } from "inversify-props";
import ConfigProvider from "@/ConfigProvider";

const backendBaseUrl = ConfigProvider.value("backendBaseUrl");

// @injectable()
export default class HttpClient {
  constructor() {
    // add retry mechanism for both get and post methods if retryable error
    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 2000; // time interval between retries
      },
      retryCondition: axiosRetry.isRetryableError // retry on Network Error & 5xx responses
    });
  }

  get<TRequest, TResponse>(
    parameters: IHttpClientRequestParameters<TRequest>
  ): Promise<TResponse> {
    return new Promise<TResponse>((resolve, reject) => {
      // extract the individual parameters
      const { url, requiresToken } = parameters;

      // axios request options like headers etc
      const options: AxiosRequestConfig = {
        baseURL: backendBaseUrl,
        headers: {}
      };

      // if API endpoint requires a token, get token from Vuex
      if (requiresToken) {
        const token = store.getters["auth/token"];
        // options.headers.RequestVerificationToken = token;
        options.headers.Authorization = "Bearer " + token;
      }

      // finally execute the GET request with axios:
      axios
        .get(url, options)
        .then((response: any) => {
          if (response === undefined) {
            reject(new Error("No response from server"));
          }
          resolve(response.data as TResponse);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getRawResponse(parameters: IHttpClientRequestParameters<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      // extract the individual parameters
      const { url, requiresToken } = parameters;

      // axios request options like headers etc
      const options: AxiosRequestConfig = {
        baseURL: backendBaseUrl,
        headers: {}
      };

      // if API endpoint requires a token, get token from Vuex
      if (requiresToken) {
        const token = store.getters["auth/token"];
        options.headers.Authorization = "Bearer " + token;
      }

      // finally execute the GET request with axios:
      axios
        .get(url, options)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  post<TRequest, TResponse>(
    parameters: IHttpClientRequestParameters<TRequest>
  ): Promise<TResponse> {
    return new Promise<TResponse>((resolve, reject) => {
      const { url, payload, requiresToken, headers } = parameters;

      // axios request options like headers etc
      const options: AxiosRequestConfig = {
        baseURL: backendBaseUrl,
        headers: {}
      };

      // if API endpoint requires a token, we'll need to add a way to add this.
      if (requiresToken) {
        const token = store.getters["auth/token"];
        // options.headers.RequestVerificationToken = token;
        options.headers.Authorization = "Bearer " + token;
      }
      if (headers !== null) {
        for (const header in headers) {
          options.headers[header] = headers[header];
        }
      }
      axios
        .post(url, payload, options)
        .then((response: any) => {
          resolve(response.data as TResponse);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  postRaw<TRequest>(
    parameters: IHttpClientRequestParameters<TRequest>
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const { url, payload, requiresToken, headers } = parameters;

      // axios request options like headers etc
      const options: AxiosRequestConfig = {
        baseURL: backendBaseUrl,
        headers: {},
        responseType: "blob"
      };

      // if API endpoint requires a token, we'll need to add a way to add this.
      if (requiresToken) {
        const token = store.getters["auth/token"];
        // options.headers.RequestVerificationToken = token;
        options.headers.Authorization = "Bearer " + token;
      }
      if (headers !== null) {
        for (const header in headers) {
          options.headers[header] = headers[header];
        }
      }
      axios
        .post(url, payload, options)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
