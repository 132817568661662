
import Vue from "vue";
import { Component } from "vue-property-decorator";
import MarketingHeader from "../components/marketing/MarketingHeader.vue";
import MarketingInfo from "../components/marketing/MarketingInfo.vue";
import MarketingFooter from "../components/marketing/MarketingFooter.vue";

@Component({
  components: {
    MarketingHeader,
    MarketingInfo,
    MarketingFooter
  }
})
export default class MarketingPage extends Vue {}
