
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
// import { Inject } from "inversify-props";
import { Inject, cid, container } from "inversify-props";
import AuthService from "@/services/impl/AuthService";
const namespace = "auth";

@Component
export default class StudyLogin extends Vue {
  // @Inject() private authService!: AuthService;
  private authService!: AuthService;
  // private authService!: IAuthService;
  @Getter("isLoggedIn", { namespace }) isLoggedIn!: boolean;

  isLoading = false;

  async created() {
    // this.authService = container.get<IAuthService>(cid.IAuthService);
    this.authService = (this as any).$authService as AuthService;
    if (!this.authService) {
      throw new Error("Cannot access (this as any).$authService as AuthService");
    }
    console.info(this.authService);

    if (this.isLoggedIn) {
      // this.$router.push({ name: "StudyHomePage" });
    }
  }

  async login() {
    this.isLoading = true;
    await this.authService.login().then(() =>
      setTimeout(() => {
        this.isLoading = false;
      }, 2000)
    );
  }
}
