
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class AppSnackbar extends Vue {
  show = false;
  message = "";
  color = "";

  created() {
    this.$store.subscribe((mutation: any, state: any) => {
      if (mutation.type === "snackbar/showSnackbar") {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.show = true;
      }
    });
  }
}
