import { Module } from "vuex";
import { mutations } from "./mutations";
import { AlertState } from "./types";
import { RootState } from "../types";

export const state: AlertState = {
  content: "",
  type: ""
};

const namespaced = true;

// Alert is the module state, RootState is state for Vuex store
export const alert: Module<AlertState, RootState> = {
  namespaced,
  state,
  mutations
};
