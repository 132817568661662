
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Inject } from "inversify-props";
import AuthService from "@/services/impl/AuthService";
// import { IAuthService } from "@/services/IAuthService";

@Component
export default class SilentRenew extends Vue {
  // @Inject() private authService!: IAuthService;
  private authService!: AuthService;

  async created() {
    this.authService = (this as any).$authService as AuthService;
    if (!this.authService) {
      throw new Error("Cannot access (this as any).$authService as AuthService");
    }
    await this.authService.signinSilentCallback();
  }
}
