
import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppBar from "../components/AppBar.vue";
import StudyLogin from "../components/study/StudyLogin.vue";

@Component({
  name: "StudyLoginPage",
  components: {
    AppBar,
    StudyLogin
  }
})
export default class StudyLoginPage extends Vue {}
