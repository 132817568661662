import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { reports } from "./reports/index";
import { auth } from "./auth/index";
import { alert } from "./alert/index";
import { snackbar } from "./snackbar/index";
import { survey } from "./survey/index";
import { study } from "./study/index";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    reports,
    auth,
    alert,
    snackbar,
    survey,
    study
  },
  plugins: [createPersistedState()]
};

export default new Vuex.Store<RootState>(store);
