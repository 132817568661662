
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Inject } from "inversify-props";
// import { IAuthService } from "@/services/IAuthService";
import { Getter } from "vuex-class";
import { UserDetails } from "@/models/UserDetails";
// import AppAvatar from "./AppAvatar.vue";
import AppHelp from "./AppHelp.vue";
import AuthService from "@/services/impl/AuthService";
import LocalStorageService from "@/services/impl/LocalStorageService";
const namespace = "auth";

@Component({
  name: "AppBar",
  components: {
    // AppAvatar,
    AppHelp
  }
})
export default class AppBar extends Vue {
  // @Inject() private authService!: IAuthService;
  @Getter("isLoggedIn", { namespace }) isLoggedIn!: boolean;
  @Getter("currentUser", { namespace }) currentUser!: UserDetails;
  @Getter("allUsers", { namespace }) allUsers!: UserDetails[];
  authService!: AuthService
  localStorageService = new LocalStorageService();

  created() {
    this.authService = (this as any).$authService as AuthService;
    if (!this.authService) {
      throw new Error("Cannot access (this as any).$authService as AuthService");
    }
  }

  async logout() {
    // eslint-disable-next-line  @typescript-eslint/no-this-alias
    const $self = this;
    // $self.localStorageService.clear();
    await this.authService
      .logout();
    // $self.$store.dispatch("auth/logoutUser");
    // // .then(() => {
    // // });
    // setTimeout(() => {
    //   $self.$router.push({ name: "StudyLoginPage" });
    // }, 2000);
  }

  links = [
    {
      label: "Study",
      component: { name: "StudyHomePage" }
    },
    {
      label: "Intro",
      component: { name: "StudyIntroPage" }
    },
    {
      label: "Part 1",
      component: { name: "StudyPartHomePage", params: { partNumber: "1" } }
    },
    {
      label: "Part 2",
      component: { name: "StudyPartHomePage", params: { partNumber: "2" } }
    },
    {
      label: "Survey",
      component: { name: "StudySurveyPage" }
    }
  ];

  get accessibleLinks() {
    if (this.currentUser.isAdmin) {
      return this.links;
    }
    return this.links.filter(l => l.label.indexOf("Part ") === -1);
  }

  adminLinks = [
    {
      label: "Users",
      component: { name: "UserPage" }
    },
    {
      label: "Angiograms",
      component: { name: "AngiogramEditorPage" }
    },
    {
      label: "Reporting",
      component: { name: "StudyReportPage" }
    }
  ];
}
