import { ActionTree } from "vuex";
import { AuthState } from "./types";
import { RootState } from "../types";
import { User } from "oidc-client";
import { UserDetails } from "@/models/UserDetails";
import { cid, container } from "inversify-props";
import UserService from "@/services/impl/UserService";

export const actions: ActionTree<AuthState, RootState> = {
  async fetchAllUsers(context) {
    const userService = new UserService();
    await userService.getAllUsers().then((users: UserDetails[]) => {
      context.commit("setAllUsers", users);
    })
      .catch((error: Error) => console.error(error));
  },

  loginUser(context, principal: User) {
    context.commit("setPrincipal", principal);
    context.commit("setToken", principal.access_token);
  },

  logoutUser(context) {
    context.commit("removeToken");
    context.commit("removePrincipal");
  }

};
