import { ActionTree } from "vuex";
import { SurveyState } from "./types";
import { RootState } from "../types";
import { cid, container } from "inversify-props";
import SurveyFormService from "@/services/impl/SurveyFormService";
import { SurveyFormDetails } from "@/models/SurveyFormDetails";
import StudyService from "@/services/impl/StudyService";

export const actions: ActionTree<SurveyState, RootState> = {
  async fetchSurveyForUser(context) {
    const surveyFormService = new SurveyFormService();
    await surveyFormService
      .fetchSurveyForUser()
      .then((survey: SurveyFormDetails) => {
        context.commit("setIsLoading", false);
        context.commit("setCurrentSurvey", survey);
      })
      .catch((error: Error) => console.error(error));
  },

  updateCurrentSurvey(context, survey: SurveyFormDetails) {
    console.log("update current survey");
    context.commit("updateCurrentSurvey", survey);
  },

  setError(context, error: string) {
    context.commit("setCurrentError", error);
    context.commit("addErrorToList", error);
  }
};
