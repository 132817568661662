
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { ReportsState } from "@/store/reports/types";
import { LesionReportDetails } from "@/models/LesionReportDetails";
import { AngiogramDetails } from "@/models/AngiogramDetails";
import AngiogramService from "@/services/impl/AngiogramService";
import { SequenceDetails, SequenceType } from "@/models/SequenceDetails";
import { FrameDetails, FrameFileType } from "@/models/FrameDetails";
import FileDownloadService from "@/services/impl/FileDownloadService";
import { FileDownload } from "@/models/FileDownload";
import { UserDetails } from "@/models/UserDetails";
// External dependencies
import Hammer from "hammerjs";
// Cornerstone Libraries
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import * as cornerstoneMath from "cornerstone-math";
// Bootstrap-vue icons
import { BIcon, BIconArrowUp, BIconArrowDown } from "bootstrap-vue";

// Specify external dependencies
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstoneMath = cornerstoneMath;

const namespace = "reports";

@Component({
  name: "StudyViewer",
  components: {
    BIcon,
    BIconArrowUp,
    BIconArrowDown
  }
})
export default class StudyViewer extends Vue {
  $self = this;

  predictionEvaluated = false;
  public predictionFrames = new Array<FrameDetails>();
  public predictionSingleFrame: FrameDetails = new FrameDetails();
  public predictionStack = {
    currentImageIdIndex: 0,
    imageIds: [] as any
  };

  private fileDownloadService = new FileDownloadService();
  private angiogramService = new AngiogramService();

  currentAngiogram = new AngiogramDetails();
  currentSequence!: SequenceDetails;
  mainSequenceType = SequenceType.Main;
  auxSequenceType = SequenceType.Auxiliary;
  isLoading = true;
  isLoaded = false;
  isPlaying = false;
  selectedSequence: SequenceDetails | null = null;
  selectedSequenceType = SequenceType.Main;

  activeTool = "";
  numFrames = 0;
  frameRate = 15;
  // note: stack image id index starts at 0, but frame number displayed starts at 1
  dicomStack = {
    currentImageIdIndex: 0,
    imageIds: [] as any
  };

  currentView: SequenceType | null = null; // = SequenceType.Main; this.mainSequenceType;

  downloads: Promise<any>[] = [];
  enablePredictionView = false;
  stacks!: any;
  $refs!: {
    cornerstoneDicomTarget: any;
    // cornerstoneDeepangioTarget: any;
  };

  @State("reports") reports!: ReportsState;
  @Getter("selectedPart", { namespace }) selectedPart!: number;
  @Getter("selectedReport", { namespace }) selectedReport!: LesionReportDetails;
  @Getter("currentUser", { namespace: "auth" }) currentUser!: UserDetails;

  created() {
    this.configureViewer();
  }

  async mounted() {
    this.enablePredictionView = this.selectedPart === 2;
    // this.currentView = SequenceType.Main; // this.mainSequenceType;
    // enable dicom element regardless of which study part we're in
    cornerstone.enable(this.$refs.cornerstoneDicomTarget);
    // if it is part 2, get and enable the cornerstone deepangio element
    if (this.enablePredictionView) {
      // cornerstone.enable(this.$refs.cornerstoneDeepangioTarget);
    }

    // get the current angiogram
    await this.angiogramService
      .getAngiogram(this.selectedReport.angiogramId)
      .then((response: AngiogramDetails) => {
        this.currentAngiogram = response;
      })
      .catch((error: string) => console.log(error));
    await this.setView(this.mainSequenceType);
    // await this.loadAndDisplayFrames(this.currentView);

    console.log("enabled elements: ", cornerstone.getEnabledElements());
    console.log("tools: ", cornerstoneTools.store.state.tools);
    this.isLoading = false;
  }

  get availableSequences(): Array<SequenceDetails> {
    if (this.currentAngiogram === null || this.currentAngiogram === undefined) {
      return [];
    }
    return this.currentAngiogram.sequences.sort((a: SequenceDetails, b: SequenceDetails) => a.sequenceType - b.sequenceType);
  }

  get mainSequence(): SequenceDetails {
    return this.availableSequences[0];
  }

  get auxSequence(): SequenceDetails {
    return this.availableSequences[1];
  }

  async setView(toView: SequenceType): Promise<void> {
    // only change the view if current view and toView are different
    if (this.currentView == null || this.currentView !== toView) {
      this.isLoading = true;
      if (this.isPlaying === true) {
        const allEnabledElements = cornerstone.getEnabledElements();
        allEnabledElements.forEach((e: { element: any }) => {
          cornerstoneTools.stopClip(e.element);
        });
      }
      // change the currentView property and display the other view
      this.currentView = toView;
      await this.loadAndDisplayFrames(toView);
      this.isLoading = false;
    }
  }

  get singlePredictionFrame(): FrameDetails | null {
    return this.predictionSingleFrame;
  }

  processFrames(frames: FrameDetails[]): FrameDetails[] {
    const predictions = frames
      .filter(f => {
        return FrameDetails.isPredictionFrame(f) === true;
      });
    this.predictionFrames.splice(0, this.predictionFrames.length);
    this.predictionFrames.push(...predictions);
    if (!this.isMultiFrames && this.predictionFrames.length > 0) {
      this.predictionSingleFrame = this.predictionFrames[0];
    }
    return this.predictionFrames;
  }

  getImageIds(): string[] {
    return this.predictionFrames!.map(
      (fd: { predictionFileSrcDownloadUrl: string }) =>
        fd.predictionFileSrcDownloadUrl
    );
  }

  get isMultiFrames(): boolean {
    return this.predictionNumFrames > 1;
  }

  get isSingleFrame(): boolean {
    return this.predictionNumFrames === 1;
  }

  get predictionNumFrames(): number {
    return this.predictionFrames?.length ?? 0;
  }

  get hasPrediction(): boolean {
    return this.predictionNumFrames > 0;
  }

  get summary(): string {
    if (!this.hasPrediction) {
      return "No prediction";
    }
    if (this.isMultiFrames) {
      return `Multi Dicom frames prediction (${this.predictionNumFrames} frames)`;
    }
    return `Dicom frame #${this.singlePredictionFrame?.number} with a predicted lesion obstruction of ${this.singlePredictionFrame?.predictionSeverityPercentage} %`;
  }

  async loadAndDisplayFrames(view: SequenceType): Promise<void> {
    // get all imageIds for the stack depending on the view
    if (view === this.mainSequenceType) {
      this.currentSequence = this.currentAngiogram.sequences.filter(
        sd => sd.sequenceType === SequenceType.Main
      )[0];
    } else if (view === this.auxSequenceType) {
      this.currentSequence = this.currentAngiogram.sequences.filter(
        sd => sd.sequenceType === SequenceType.Auxiliary
      )[0];
    }
    const frames = this.currentSequence.frames;
    const allSourceFrames = frames
      .filter(f => true);
    // .map(f => f.sourceFileSrc);
    const pFrames = this.processFrames(frames);
    // .map(f => f.predictionFileSrc);
    this.numFrames = frames.length;
    for (let frameNumber = 0; frameNumber < frames.length; frameNumber++) {
      const frame = frames[frameNumber];
      const fileName = frame.sourceFileSrc;
      const fileDownload = this.fileDownloadService
        .getFileDownload(
          this.currentAngiogram.id,
          this.currentSequence.sequenceType.toString(),
          FrameFileType.Source.toString(),
          fileName
        )
        .then((response: FileDownload) => {
          const targetFrame = frames.filter(fd => fd.sourceFileSrc === response.relativeFilePath)[0];
          // ajax call to download url (return value is s3 url)
          // return axios.get(downloadUrl)
          targetFrame.sourceFileSrcDownloadUrl = response.downloadUrl;
        })
        .catch((error: string) => console.log(error));
      this.downloads.push(fileDownload);

      // if part 2, we need to also get the imageIds for all the deepangio frames
      if (this.enablePredictionView && this.isMultiFrames) {
        if (!FrameDetails.isPredictionFrame(frame)) {
          continue;
        }
        const deepAngioFileName = frame.predictionFileSrc;
        const fileDownload = this.fileDownloadService
          .getFileDownload(
            this.currentAngiogram.id,
            this.currentSequence.sequenceType.toString(),
            FrameFileType.Prediction.toString(),
            deepAngioFileName
          )
          .then((response: FileDownload) => {
            const predictionTargetFrame = frames.filter(fd => fd.predictionFileSrc === response.relativeFilePath)[0];
            predictionTargetFrame.predictionFileSrcDownloadUrl =
              response.downloadUrl;
          })
          .catch((error: string) => console.log(error));
        this.downloads.push(fileDownload);
      }
    }

    if (this.hasPrediction && this.isSingleFrame) {
      if (this.singlePredictionFrame === null) {
        console.error("missing single prediction frame");
      }
      const predictionFileName = this.singlePredictionFrame!.predictionFileSrc;
      const fileDownload = this.fileDownloadService
        .getFileDownload(
          this.currentAngiogram.id,
          this.currentSequence.sequenceType.toString(),
          FrameFileType.Prediction.toString(),
          predictionFileName
        )
        .then((response: FileDownload) => {
          this.singlePredictionFrame!.predictionFileSrcDownloadUrl = response.downloadUrl;
        })
        .catch((error: string) => console.log(predictionFileName, error));
      this.downloads.push(fileDownload);
    }

    // wait for all downloads to finish
    await Promise.all(this.downloads);

    // define the dicom stack object
    this.dicomStack = {
      currentImageIdIndex: 0,
      imageIds: frames.map(
        (fd: { sourceFileSrcDownloadUrl: string }) =>
          fd.sourceFileSrcDownloadUrl
      )
    };

    this.stacks = [
      { element: this.$refs.cornerstoneDicomTarget, stack: this.dicomStack }
    ];

    // if part 2, also define the deepangio stack object, and add to stacks property
    if (this.enablePredictionView && this.isMultiFrames) {
      this.predictionStack = {
        currentImageIdIndex: 0,
        imageIds: this.getImageIds()
      };
      this.stacks.push({
        // element: this.$refs.cornerstoneDeepangioTarget,
        stack: this.predictionStack
      });
    }

    // declare synchronizer
    // const indexSync = new cornerstoneTools.Synchronizer(
    //   "cornerstonenewimage",
    //   cornerstoneTools.stackImageIndexSynchronizer
    // );
    // const scrollSyn = new cornerstoneTools.Synchronizer(
    //   "cornerstonetoolsstackscroll",
    //   cornerstoneTools.stackScrollSynchronizer
    // );

    // load the proper image in each viewport
    for (let s = 0; s < this.stacks.length; s++) {
      const element = this.stacks[s].element;
      const stack = this.stacks[s].stack;
      for (let f = 0; f < stack.imageIds.length; f++) {
        const imageId = stack.imageIds[f];
        if (imageId === undefined) {
          continue;
        }
        const image = await cornerstone.loadAndCacheImage(imageId);

        if (!element) {
          continue;
        }
        if (f === 0) {
          // load cornerstone tools and display first image
          cornerstone.displayImage(element, image);

          // set the stack as tool state
          cornerstoneTools.addStackStateManager(element, ["stack", "playClip"]);
          cornerstoneTools.addToolState(element, "stack", stack);

          // indexSync.add(element);
          // scrollSyn.add(element);

          // add the tools we will need if they're not already added for the enabled element
          const toolsForEnabledElement = cornerstoneTools.store.state.tools.filter(
            (tool: { element: any }) => tool.element === element
          );
          if (
            toolsForEnabledElement === undefined ||
            toolsForEnabledElement.length === 0
          ) {
            cornerstoneTools.addToolForElement(
              element,
              cornerstoneTools.StackScrollMouseWheelTool
            );
            cornerstoneTools.addToolForElement(
              element,
              cornerstoneTools.ZoomTool
            );
            cornerstoneTools.addToolForElement(
              element,
              cornerstoneTools.PanTool
            );
          }
        }
      }
    }

    cornerstoneTools.playClip(
      this.$refs.cornerstoneDicomTarget,
      this.frameRate
    );
    this.isPlaying = true;

    // activate stack scroll mouse wheel tool so users can scroll through frames one at a time
    cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
  }

  setKeyEvents(event: { key: string; preventDefault: () => void }): void {
    // if user presses spacebar, toggle play/pause
    if (event.key === " ") {
      event.preventDefault();
      this.togglePlayClip();
    }
    // if user presses left arrow key, view next frame
    if (event.key === "ArrowLeft") {
      this.viewPreviousFrame();
    }
    if (event.key === "ArrowRight") {
      this.viewNextFrame();
    }
  }

  async viewLastFrame(): Promise<void> {
    // if we're not already viewing the last frame,
    // set the image id index of the dicom stack to the last frame
    // deepangio stack will sync up with dicom stack due to indexSynchronizer
    if (
      this.dicomStack.currentImageIdIndex !==
      this.dicomStack.imageIds.length - 1
    ) {
      this.dicomStack.currentImageIdIndex = this.dicomStack.imageIds.length - 1;

      for (let s = 0; s < this.stacks.length; s++) {
        const element = this.stacks[s].element;
        const stack = this.stacks[s].stack;
        const imageId = stack.imageIds[stack.currentImageIdIndex];
        if (!imageId) {
          continue;
        }
        await cornerstone
          .loadAndCacheImage(imageId)
          .then((image: any) => {
            // display this image
            cornerstone.displayImage(element, imageId);
          });
      }
    }
  }

  async viewNextFrame(): Promise<void> {
    // increment the image id index of the dicom stack if we're not at last frame
    // deepangio stack will sync up with dicom stack due to the indexSynchronizer
    if (
      this.dicomStack.currentImageIdIndex <
      this.dicomStack.imageIds.length - 1
    ) {
      this.dicomStack.currentImageIdIndex++;
    }

    for (let s = 0; s < this.stacks.length; s++) {
      const element = this.stacks[s].element;
      const stack = this.stacks[s].stack;
      const imageId = stack.imageIds[stack.currentImageIdIndex];
      if (!imageId) {
        continue;
      }
      await cornerstone
        .loadAndCacheImage(imageId)
        .then((image: any) => {
          // display this image
          cornerstone.displayImage(element, image);
        });
    }
  }

  async viewFirstFrame(): Promise<void> {
    // if we're not already viewing the first frame,
    // set the image id index of the dicom stack to the first frame
    // deepangio stack will sync up with dicom stack due to the indexSynchronizer
    if (this.dicomStack.currentImageIdIndex !== 0) {
      this.dicomStack.currentImageIdIndex = 0;

      for (let s = 0; s < this.stacks.length; s++) {
        const element = this.stacks[s].element;
        const stack = this.stacks[s].stack;
        const imageId = stack.imageIds[stack.currentImageIdIndex];
        if (!imageId) {
          continue;
        }
        await cornerstone
          .loadAndCacheImage(imageId)
          .then((image: any) => {
            cornerstone.displayImage(element, image);
          });
      }
    }
  }

  async viewPreviousFrame(): Promise<void> {
    // decrement the image id index of the dicom stack if we're not at last frame
    // deepangio stack will sync up with dicom stack due to the indexSynchronizer
    if (this.dicomStack.currentImageIdIndex > 0) {
      this.dicomStack.currentImageIdIndex--;
    }

    for (let s = 0; s < this.stacks.length; s++) {
      const element = this.stacks[s].element;
      const stack = this.stacks[s].stack;
      const imageId = stack.imageIds[stack.currentImageIdIndex];
      if (!imageId) {
        continue;
      }
      await cornerstone
        .loadAndCacheImage(imageId)
        .then((image: any) => {
          // display this image
          cornerstone.displayImage(element, image);
        });
    }
  }

  togglePlayClip(): void {
    if (this.isPlaying === true) {
      // already playing, so pause clip(s) and update isPlaying
      for (let s = 0; s < this.stacks.length; s++) {
        const element = this.stacks[s].element;
        if (element) {
          cornerstoneTools.stopClip(element);
        }
      }
      this.isPlaying = false;
    } else if (this.isPlaying === false) {
      // paused, so play dicom clip (deepangio will also play if part 2 due to synchronization)
      cornerstoneTools.playClip(
        this.$refs.cornerstoneDicomTarget,
        this.frameRate
      );
      this.isPlaying = true;
    }
  }

  public toggleToolState(toolName: string): void {
    // get all validToggleTools and exclude stack scroll mouse wheel because we don't want to toggle its state
    const validToggleTools = cornerstoneTools.store.state.tools.filter(
      (tool: { name: any }) => tool.name !== "StackScrollMouseWheel"
    );

    const validToggleToolNames = validToggleTools.map(
      (tool: { name: any }) => tool.name
    );
    if (!validToggleToolNames.includes(toolName)) {
      console.warn(
        `Trying to toggle a tool's state that is not "added". Available tools include: ${validToggleToolNames.join(
          ", "
        )}`
      );
    }
    // need to see if there is another tool that is already active that is not the toggleTool
    const otherActiveTools = validToggleTools.filter(
      (tool: { mode: any; name: string }) =>
        tool.mode === "active" && tool.name !== toolName
    );

    // if there is another active tool, we need to set its state to disabled
    if (otherActiveTools.length !== 0) {
      const toolNameToDisable = otherActiveTools[0].name;
      cornerstoneTools.setToolDisabled(toolNameToDisable);
      this.activeTool = "";
    }

    const tool = validToggleTools.filter(
      (tool: { name: any }) => tool.name === toolName
    );
    const toggleTool = tool[0];

    if (toggleTool.mode === "active") {
      cornerstoneTools.setToolDisabled(toolName);
      this.activeTool = "";
    } else if (toggleTool.mode === "disabled") {
      cornerstoneTools.setToolActive(toolName, {
        mouseButtonMask: 1
      });
      this.activeTool = toolName;
    }
  }

  configureViewer(): void {
    // register the cornerstone Web Image Loader
    cornerstone.registerImageLoader(
      "https",
      cornerstoneWebImageLoader.loadImage
    );
    cornerstone.registerImageLoader(
      "http",
      cornerstoneWebImageLoader.loadImage
    );

    // set up cornerstone tools
    cornerstoneTools.init();
  }

  resetZoomAndPan(): void {
    const allEnabledElements = cornerstone.getEnabledElements();
    console.log("resetZoomAndPan enabledElements: ", allEnabledElements);
    allEnabledElements.forEach((e: { element: any }) => {
      cornerstone.reset(e.element);
    });
  }

  public stopPlaying(): void {
    const allEnabledElements = cornerstone.getEnabledElements();
    allEnabledElements.forEach((e: { element: any }) => {
      cornerstoneTools.stopClip(e.element);
    });
  }

  scrollToBottomOfPage(): void {
    window.scrollTo(0, document.body.scrollHeight);
    // const el = this.$refs.scrollToMe;
    // if (el) {
    //   // Use el.scrollIntoView() to instantly scroll to the element
    //   el.scrollIntoView({behavior: 'smooth'});
    // }
  }

  prepareToExit(): void {
    const allEnabledElements = cornerstone.getEnabledElements();
    if (allEnabledElements !== undefined && allEnabledElements.length !== 0) {
      allEnabledElements.forEach((e: { element: any }) => {
        if (this.isPlaying === true) {
          cornerstoneTools.stopClip(e.element);
          this.isPlaying = false;
        }
        // cornerstone.disable(e.element);
      });
      cornerstone.disable(this.$refs.cornerstoneDicomTarget);
      if (this.enablePredictionView) {
        // cornerstone.disable(this.$refs.cornerstoneDeepangioTarget);
      }
    }
  }

  destroy(): void {
    this.prepareToExit();
    // remove event listeners for viewer's keyboard shortcuts
    window.removeEventListener("keydown", this.setKeyEvents);
  }
}
