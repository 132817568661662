
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import StudyPartReportCollection from "@/components/study/StudyPartReportCollection.vue";
import { Route } from "vue-router";
import { Action, State } from "vuex-class";
import { ReportsState } from "@/store/reports/types";
const namespace = "reports";

@Component({
  name: "StudyPartHomePage",
  components: {
    StudyPartReportCollection
  }
})
export default class StudyPartHomePage extends Vue {
  @State("reports") reports!: ReportsState;

  @Action("setSelectedPartAndReports", { namespace })
  setSelectedPartAndReports!: any;

  /* watch route so we can do something when it changes
  set immediate to true so watcher fires immediately upon page load
  set deep to true so Vue looks inside the route data */
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(route: Route) {
    const partNumberString = route.params.partNumber;
    const partNumber = parseInt(partNumberString);
    this.setSelectedPartAndReports(partNumber);
  }
}
