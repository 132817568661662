import { AngiogramDetails } from "@/models/AngiogramDetails";
import { SequenceType } from "@/models/SequenceDetails";
import { Inject, injectable } from "inversify-props";
import HttpClient from "./HttpClient";
// import { IUploadFilesService } from "../IUploadFilesService";
import { FrameFileType } from "@/models/FrameDetails";

// @injectable()
export default class UploadFilesService {
  private httpClient =new HttpClient();

  upload(fileList: FileList, onUploadProgress: any, angioId: string, sequenceType: SequenceType, frameFileType: FrameFileType, clearFrames: boolean): Promise<AngiogramDetails> {
    const formData = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      formData.append("files", file, file.name);
    }

    const encodedAngioId = encodeURIComponent(angioId);
    if (encodedAngioId === null || encodedAngioId === undefined) {
      // eslint-disable-next-line no-debugger
      debugger;
    }

    const getParameters = {
      url: `/api/angiogram/upload/${encodeURIComponent(angioId)}/${encodeURIComponent(sequenceType)}/${frameFileType}/${encodeURIComponent(clearFrames)}`,
      payload: formData,
      requiresToken: true,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    };
    return this.httpClient.post<FormData, AngiogramDetails>(getParameters);
  }

  // getFiles(): Promise<any> {
  //   const getParameters = {
  //     url: "/",
  //     requiresToken: true,
  //     headers: null
  //   };

  //   return this.httpClient.get<any, any>(getParameters);
  // }
}
