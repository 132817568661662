import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Update extends Vue {
  /* store the sw registration and add a flag to tell whether an update is available */
  registration!: ServiceWorkerRegistration;
  updateExists = false;
  refreshing = false;

  created() {
    /* setting the once option to true allows the listener to be called only once and removes the listener once invoked */
    document.addEventListener("swUpdated", this.updateAvailable, { once: true });

    /* prevent multiple refreshes */
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return false;
      this.refreshing = true;
      /* here the actual reload of the page occurs */
      window.location.reload();
    });
  }

  /* store the SW registration so we can send it a message
  we use updateExists to control the alert to notify users when there is an update they need to refresh for  */
  updateAvailable(event: any) {
    console.log("event: ", event);
    console.log("type of event: ", typeof (event));
    this.registration = event.detail;
    this.updateExists = true;
  }

  /* once a service worker is registered, it "waits" until the previously registered sw is no longer controlling the client
  by telling the new sw to skip waiting, we skip this waiting period  */
  refreshApp() {
    this.updateExists = false;
    /* make sure we only send a "skip waiting" message if the service worker is waiting */
    if (!this.registration || !this.registration.waiting) return false;
    /* send message to sw to skip the waiting and activate the new SW */
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
}
