import { SequenceDetails } from "./SequenceDetails";

export class AngiogramDetails {
  constructor() {
    this.sequences = new Array<SequenceDetails>();
  }

  public id!: string;
  public name!: string;
  public sequences!: Array<SequenceDetails>;
  public lesionLocations!: Array<string>;
  public isTest = false;
}
