
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Inject } from "inversify-props";
import AuthService from "@/services/impl/AuthService";
// import { IAuthService } from "@/services/IAuthService";

@Component
export default class PostLogout extends Vue {
  // @Inject() private authService!: IAuthService;
  private authService!: AuthService;

  async created() {
    this.authService = (this as any).$authService as AuthService;
    if (!this.authService) {
      // eslint-disable-next-line no-debugger
      debugger;
      throw new Error("Cannot access (this as any).$authService as AuthService");
    }
    // await this.authService.signinSilentCallback();
    await this.$store.dispatch("auth/logoutUser");
    this.$router.push({ name: "StudyLoginPage" });
  }
}
