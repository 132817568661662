
import Vue from "vue";
import { Component } from "vue-property-decorator";
import StudyViewer from "@/components/study/StudyViewer.vue";
import StudyReportForm from "@/components/study/StudyReportForm.vue";
import { Getter } from "vuex-class";
const namespace = "reports";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave"
]);

@Component({
  name: "StudyPartFormPage",
  components: {
    StudyViewer,
    StudyReportForm
  }
})
export default class StudyPartFormPage extends Vue {
  @Getter("selectedPart", { namespace }) selectedPart!: number;

  /* if using ref on an element in a template and referencing it with this.ref
  you need to type it to access properties of that element */
  $refs!: {
    studyViewer: any;
  };

  /* this can only be used on elements specified in the router file (not subcomponents) */
  beforeRouteLeave(to: any, from: any, next: any) {
    // indicate to the subcomponent that we are leaving the route
    this.$refs.studyViewer.prepareToExit();
    // call the next function to resolve the hook
    next();
  }
}
