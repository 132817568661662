
import Vue from "vue";
import { Component } from "vue-property-decorator";
// import ModalIdle from "@/components/ModalIdle.vue";
import { Getter } from "vuex-class";
import Update from "./mixins/Update";
import AppSnackbar from "@/components/AppSnackbar.vue";
const namespace = "auth";

@Component({
  name: "App",
  components: {
    // ModalIdle,
    AppSnackbar
  },
  mixins: [Update]
})
export default class App extends Vue {
  @Getter("isLoggedIn", { namespace }) isLoggedIn!: boolean;

  get isUserIdle() {
    return this.$store.state.idleVue.isIdle;
  }

  get appLayout() {
    return this.$route.meta?.layout;
  }
}
