
import Vue, { watch, ref } from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component({
  name: "ViewerTestPage"
})
export default class ViewerTestPage extends Vue {

}
